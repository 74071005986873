//Colors START

.swatch{
  height:200px;
  width:100%;
  //Brand Colors
  &.primary{
    background:$primary;
  }
  &.secondary{
    background:$secondary;
  }
  &.tertiary{
    background:$tertiary;
  }
  //Accent
  &.accent{
    background:$accent;
  }
  //UI Colors
  &.negative{
    background:$negative;
  }
  &.positive{
    background:$positive;
  }
  &.warning{
    background:$warning;
  }
  &.active{
    background:$active;
  }
  //Grey
  &.dark-grey{
    background:$grey-800;
  }
  &.grey{
    background:$grey-600;
  }
  &.light-grey{
    background:$grey-400;
  }
  &.lighter-grey{
    background:$grey-200;
  }
  &.snow{
    background:$light;
  }
}
//Colors END

.pre-title{
  font-family:$base-font-family;
  font-size:1.25rem;
  color:#2D8EFF;
  display:block;
  border-bottom:2px solid $grey-400;
  padding-bottom:1rem;
}

.center-text{
  text-align:center;
}

.styleguide{
  .site-header{
    margin:1rem;
  }
}

.styleguide-list{
  margin:0;
  padding:0 0 0 1rem;
  li{
    margin-bottom:1rem;
    text-align:left;
  }
}
