//
// Spacing Settings
//
$spacer: 1rem !default;

//Padding Spacing
.spacer{
  padding-bottom: $spacer;
  &-sm{
    padding-bottom: $spacer * .5;
    padding-top: $spacer * .5;
  }
  &-md{
    padding-bottom: $spacer * 2;
    padding-top: $spacer * 2;
    h1,h2,h3,h4,h5,h6{
      margin-top:0;
    }
    main{
      h1{
        @include normalize-margin(1 0, $h1-font-size);
      }
      h2 {
        @include normalize-margin(1 0, $h2-font-size);
      }

      h3 {
        @include normalize-margin(1 0, $h3-font-size);
      }

      h4 {
        @include normalize-margin(1 0, $h4-font-size);
      }

      h5 {
        @include normalize-margin(1 0 .5 0, $h5-font-size);
      }

      h6 {
        @include normalize-margin(1 0 .5 0, $h6-font-size);
      }
      h2:first-of-type{
        margin-top:0;
      }
    }
  }
  &-lg{
    padding-bottom: $spacer * 5;
    padding-top: $spacer * 5;
    h1,h2,h3,h4,h5,h6{
      margin-top:0;
    }
    main{
      h1{
        @include normalize-margin(1 0, $h1-font-size);
      }
      h2 {
        @include normalize-margin(1 0, $h2-font-size);
      }

      h3 {
        @include normalize-margin(1 0, $h3-font-size);
      }

      h4 {
        @include normalize-margin(1 0, $h4-font-size);
      }

      h5 {
        @include normalize-margin(1 0 .5 0, $h5-font-size);
      }

      h6 {
        @include normalize-margin(1 0 .5 0, $h6-font-size);
      }
      h2:first-of-type{
        margin-top:0;
      }
    }
    &-top{
      padding-bottom:0;
      padding-top:$spacer * 5;
      h1,h2,h3,h4,h5,h6{
        margin-top:0;
      }
      main{
        h1{
          @include normalize-margin(1 0, $h1-font-size);
        }
        h2 {
          @include normalize-margin(1.5 0, $h2-font-size);
        }

        h3 {
          @include normalize-margin(1 0, $h3-font-size);
        }

        h4 {
          @include normalize-margin(1 0, $h4-font-size);
        }

        h5 {
          @include normalize-margin(1 0 .5 0, $h5-font-size);
        }

        h6 {
          @include normalize-margin(1 0 .5 0, $h6-font-size);
        }
        h2:first-of-type{
          margin-top:0;
        }
      }
    }
  }
  &-xlg{
    padding-bottom: $spacer * 7;
    padding-top: $spacer * 7;
    h1,h2,h3,h4,h5,h6{
      margin-top:0;
    }
    main{
      h1{
        @include normalize-margin(1 0, $h1-font-size);
      }
      h2 {
        @include normalize-margin(1 0, $h2-font-size);
      }

      h3 {
        @include normalize-margin(1 0, $h3-font-size);
      }

      h4 {
        @include normalize-margin(1 0, $h4-font-size);
      }

      h5 {
        @include normalize-margin(1 0 .5 0, $h5-font-size);
      }

      h6 {
        @include normalize-margin(1 0 .5 0, $h6-font-size);
      }
      h2:first-of-type{
        margin-top:0;
      }
    }
  }
}

//Margin spacing
.spacer-m{
  margin-bottom: $spacer;
  &-sm{
    margin-bottom: $spacer * .5;
    margin-top: $spacer * .5;
  }
  &-md{
    margin-bottom: $spacer * 2;
    margin-top: $spacer * 2;
    h1,h2,h3,h4,h5,h6{
      margin-top:0;
    }
    main{
      h1{
        @include normalize-margin(1 0, $h1-font-size);
      }
      h2 {
        @include normalize-margin(1 0, $h2-font-size);
      }

      h3 {
        @include normalize-margin(1 0, $h3-font-size);
      }

      h4 {
        @include normalize-margin(1 0, $h4-font-size);
      }

      h5 {
        @include normalize-margin(1 0 .5 0, $h5-font-size);
      }

      h6 {
        @include normalize-margin(1 0 .5 0, $h6-font-size);
      }
      h2:first-of-type{
        margin-top:0;
      }
    }
  }
  &-lg{
    margin-bottom: $spacer * 5;
    margin-top: $spacer * 5;
    h1,h2,h3,h4,h5,h6{
      margin-top:0;
    }
    main{
      h1{
        @include normalize-margin(1 0, $h1-font-size);
      }
      h2 {
        @include normalize-margin(1 0, $h2-font-size);
      }

      h3 {
        @include normalize-margin(1 0, $h3-font-size);
      }

      h4 {
        @include normalize-margin(1 0, $h4-font-size);
      }

      h5 {
        @include normalize-margin(1 0 .5 0, $h5-font-size);
      }

      h6 {
        @include normalize-margin(1 0 .5 0, $h6-font-size);
      }
      h2:first-of-type{
        margin-top:0;
      }
    }
    &-top{
      margin-bottom:0;
      margin-top:$spacer * 5;
      h1,h2,h3,h4,h5,h6{
        margin-top:0;
      }
      main{
        h1{
          @include normalize-margin(1 0, $h1-font-size);
        }
        h2 {
          @include normalize-margin(1 0, $h2-font-size);
        }

        h3 {
          @include normalize-margin(1 0, $h3-font-size);
        }

        h4 {
          @include normalize-margin(1 0, $h4-font-size);
        }

        h5 {
          @include normalize-margin(1 0 .5 0, $h5-font-size);
        }

        h6 {
          @include normalize-margin(1 0 .5 0, $h6-font-size);
        }
        h2:first-of-type{
          margin-top:0;
        }
      }
    }
  }
  &-xlg{
    margin-bottom: $spacer * 7;
    margin-top: $spacer * 7;
    h1,h2,h3,h4,h5,h6{
      margin-top:0;
    }
    main{
      h1{
        @include normalize-margin(1 0, $h1-font-size);
      }
      h2 {
        @include normalize-margin(1 0, $h2-font-size);
      }

      h3 {
        @include normalize-margin(1 0, $h3-font-size);
      }

      h4 {
        @include normalize-margin(1 0, $h4-font-size);
      }

      h5 {
        @include normalize-margin(1 0 .5 0, $h5-font-size);
      }

      h6 {
        @include normalize-margin(1 0 .5 0, $h6-font-size);
      }
      h2:first-of-type{
        margin-top:0;
      }
    }
  }
}

@include mobile{

  //Padding Spacing
  .spacer{
    padding-bottom: $spacer;
    &-sm{
      padding-bottom: $spacer * .5;
      padding-top: $spacer * .5;
    }
    &-md{
      padding-bottom: $spacer * 1;
      padding-top: $spacer * 1;
      h1,h2,h3,h4,h5,h6{
        margin-top:0;
      }
      main{
        h1{
          @include normalize-margin(1 0, $h1-font-size);
        }
        h2 {
          @include normalize-margin(1 0, $h2-font-size);
        }

        h3 {
          @include normalize-margin(1 0, $h3-font-size);
        }

        h4 {
          @include normalize-margin(1 0, $h4-font-size);
        }

        h5 {
          @include normalize-margin(1 0 .5 0, $h5-font-size);
        }

        h6 {
          @include normalize-margin(1 0 .5 0, $h6-font-size);
        }
        h2:first-of-type{
          margin-top:0;
        }
      }
    }
    &-lg{
      padding-bottom: $spacer * 3;
      padding-top: $spacer * 3;
      h1,h2,h3,h4,h5,h6{
        margin-top:0;
      }
      main{
        h1{
          @include normalize-margin(1 0, $h1-font-size);
        }
        h2 {
          @include normalize-margin(1 0, $h2-font-size);
        }

        h3 {
          @include normalize-margin(1 0, $h3-font-size);
        }

        h4 {
          @include normalize-margin(1 0, $h4-font-size);
        }

        h5 {
          @include normalize-margin(1 0 .5 0, $h5-font-size);
        }

        h6 {
          @include normalize-margin(1 0 .5 0, $h6-font-size);
        }
        h2:first-of-type{
          margin-top:0;
        }
      }
      &-top{
        padding-bottom:0;
        padding-top:$spacer * 3;
        h1,h2,h3,h4,h5,h6{
          margin-top:0;
        }
        main{
          h1{
            @include normalize-margin(1 0, $h1-font-size);
          }
          h2 {
            @include normalize-margin(1 0, $h2-font-size);
          }

          h3 {
            @include normalize-margin(1 0, $h3-font-size);
          }

          h4 {
            @include normalize-margin(1 0, $h4-font-size);
          }

          h5 {
            @include normalize-margin(1 0 .5 0, $h5-font-size);
          }

          h6 {
            @include normalize-margin(1 0 .5 0, $h6-font-size);
          }
          h2:first-of-type{
            margin-top:0;
          }
        }
      }
    }
    &-xlg{
      padding-bottom: $spacer * 5;
      padding-top: $spacer * 5;
      h1,h2,h3,h4,h5,h6{
        margin-top:0;
      }
      main{
        h1{
          @include normalize-margin(1 0, $h1-font-size);
        }
        h2 {
          @include normalize-margin(1 0, $h2-font-size);
        }

        h3 {
          @include normalize-margin(1 0, $h3-font-size);
        }

        h4 {
          @include normalize-margin(1 0, $h4-font-size);
        }

        h5 {
          @include normalize-margin(1 0 .5 0, $h5-font-size);
        }

        h6 {
          @include normalize-margin(1 0 .5 0, $h6-font-size);
        }
        h2:first-of-type{
          margin-top:0;
        }
      }
    }
  }

  //Margin spacing
  .spacer-m{
    margin-bottom: $spacer;
    &-sm{
      margin-bottom: $spacer * .5;
      margin-top: $spacer * .5;
    }
    &-md{
      margin-bottom: $spacer * 1;
      margin-top: $spacer * 1;
      h1,h2,h3,h4,h5,h6{
        margin-top:0;
      }
      main{
        h1{
          @include normalize-margin(1 0, $h1-font-size);
        }
        h2 {
          @include normalize-margin(1 0, $h2-font-size);
        }

        h3 {
          @include normalize-margin(1 0, $h3-font-size);
        }

        h4 {
          @include normalize-margin(1 0, $h4-font-size);
        }

        h5 {
          @include normalize-margin(1 0 .5 0, $h5-font-size);
        }

        h6 {
          @include normalize-margin(1 0 .5 0, $h6-font-size);
        }
        h2:first-of-type{
          margin-top:0;
        }
      }
    }
    &-lg{
      margin-bottom: $spacer * 3;
      margin-top: $spacer * 3;
      h1,h2,h3,h4,h5,h6{
        margin-top:0;
      }
      main{
        h1{
          @include normalize-margin(1 0, $h1-font-size);
        }
        h2 {
          @include normalize-margin(1 0, $h2-font-size);
        }

        h3 {
          @include normalize-margin(1 0, $h3-font-size);
        }

        h4 {
          @include normalize-margin(1 0, $h4-font-size);
        }

        h5 {
          @include normalize-margin(1 0 .5 0, $h5-font-size);
        }

        h6 {
          @include normalize-margin(1 0 .5 0, $h6-font-size);
        }
        h2:first-of-type{
          margin-top:0;
        }
      }
      &-top{
        margin-bottom:0;
        margin-top:$spacer * 3;
        h1,h2,h3,h4,h5,h6{
          margin-top:0;
        }
        main{
          h1{
            @include normalize-margin(1 0, $h1-font-size);
          }
          h2 {
            @include normalize-margin(1 0, $h2-font-size);
          }

          h3 {
            @include normalize-margin(1 0, $h3-font-size);
          }

          h4 {
            @include normalize-margin(1 0, $h4-font-size);
          }

          h5 {
            @include normalize-margin(1 0 .5 0, $h5-font-size);
          }

          h6 {
            @include normalize-margin(1 0 .5 0, $h6-font-size);
          }
          h2:first-of-type{
            margin-top:0;
          }
        }
      }
    }
    &-xlg{
      margin-bottom: $spacer * 5;
      margin-top: $spacer * 5;
      h1,h2,h3,h4,h5,h6{
        margin-top:0;
      }
      main{
        h1{
          @include normalize-margin(1 0, $h1-font-size);
        }
        h2 {
          @include normalize-margin(1 0, $h2-font-size);
        }

        h3 {
          @include normalize-margin(1 0, $h3-font-size);
        }

        h4 {
          @include normalize-margin(1 0, $h4-font-size);
        }

        h5 {
          @include normalize-margin(1 0 .5 0, $h5-font-size);
        }

        h6 {
          @include normalize-margin(1 0 .5 0, $h6-font-size);
        }
        h2:first-of-type{
          margin-top:0;
        }
      }
    }
  }
}
