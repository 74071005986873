.services-search{
  background-color:$light;
  padding:2.5rem 0;
  .services-search__wrapper{
    >.grid{
      align-items:center;
    }
    .alpha-filter{
      margin-bottom:0;
      >a{
        margin-right:0;
      }
    }
    p,span{
      color:$grey-600;
      margin:0;
    }
    span{
      text-align:center;
      display:block;
      margin:0 auto;
    }
    .btn{
      margin-bottom:0;
    }
    .form__input-wrap{
      margin-bottom:1rem;
    }
  }
}

.filter-toggle{
  padding-bottom:5rem;
  margin-top:2rem;
  .services-list{
    margin-bottom:4rem;
    &:last-child{
      margin-bottom:0;
    }
    >li{
      margin-bottom:3rem;
      &:nth-last-child(-n+2){
        margin-bottom:0;
      }
    }
  }
  .panel-body{
    .services-alpha-header{
      display:flex;
      flex-wrap:wrap;
      flex-direction:row;
      align-items:flex-end;
      border-bottom:1px solid $grey-600;
      padding-bottom:1rem;
      span{
        flex:0 1 auto;
        font-size:1.75rem;
        font-weight:600;
      }
      .backToTop{
        margin-left:auto;
        flex:0 1 auto;
        border:0;
        background-color:transparent;
        font-size:.875rem;
        color:$grey-600;
        padding:0;
        &:after{
          content:'';
          background-image:url('../images/chevron-up-blue.svg');
          background-repeat:no-repeat;
          background-size:20px 13px;
          height:13px;
          width:20px;
          display:inline-block;
          margin-left:.5rem;
          vertical-align: middle;
        }
      }
    }
    }
    .tabs__navlist{
      background-color:#E6F0F6;
      font-size:.875rem;
      .tabs__nav-item{
        flex:0 1 auto;
      }
      &:before{
        content:'Filter By:';
        text-transform: uppercase;
        color:$secondary;
        font-size:1rem;
        display:flex;
        align-self:center;
        padding:0 30px;
        font-weight:600;
        font-family:$base-font-family;
      }
    }
  }

  .filter-toggle-mobile-toggle{
    display:none;
  }


@media (max-width:991px){
  .services-search__wrapper{
    .grid{
      >div:not(:first-child){
        margin-bottom:1.5rem;
      }
    }
    .alpha-filter{
      >a{
        margin-right:14px;
      }
    }
  }
  .filter-toggle{
    padding-bottom:3rem;
    .services-list{
      >li{
        margin-bottom:3rem;
        &:nth-last-child(-n+2){
          margin-bottom:3rem;
        }
        &:last-child{
          margin-bottom:0;
        }
      }
    }
    .tabs__navlist{
      flex-direction:column;
          border-bottom:0;
      &:before{
        content:none;
      }
      .tabs__nav-item{
        .tabs__nav-trigger{
          text-align:left;
          &:after{
            content:none;
          }
        }
      }
    }
    .filter-toggle-mobile-toggle{
      width:100%;
      text-align:left;
      background-color:#E6F0F6;
      font-size:.875rem;
      border:0;
      color:$secondary;
      padding:.6rem 3rem .6rem 1.5rem;
      display:flex;
      font-weight:bold;
      font-size:1rem;
      border-radius:4px;
      &:before{
        content:'Filter By:';
        text-transform: uppercase;
        color:$secondary;
        font-size:1rem;
        display:flex;
        align-self:center;
        padding:0 30px 0 0;
        font-weight:600;
        font-family:$base-font-family;
      }
      &:after{
        background-image:url("../images/icon-plus.svg");
        right:15px;
      }
      &.open:after{
        background-image:url("../images/icon-minus.svg");
        height:5px;
        right:15px;
      }
    }
  }
}
