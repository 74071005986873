.services-header{
  h2{
    font-size:1.125rem;
    font-family:$base-font-family;
    margin:0 0 .25rem 0;
    font-weight:600;
    line-height:1.5rem;
    &:after{
      content:none;
    }
  }
}

.services-body{
  p{
    margin:0;
  }
}
