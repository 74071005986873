//modals
.site-logo.rev{
    background-image:url('../images/mclaren-logo.svg');
    background-repeat:no-repeat;
    background-size:110px 19px;
    width:110px;
    height:19px;
    display:block;
    margin:0 auto 0 0;
}
.modaal-overlay{
  background:rgba(0, 68, 106, .97) !important;
  opacity:1 !important;
  z-index:9998;
}

.modaal-container{
  max-width:1440px;
  background:transparent;
  box-shadow:none;
  color:#fff;
  .grid{
    margin:0 -15px;
    >.col-6{
      &:nth-child(odd){
        padding-right:30px;
      }
      &:nth-child(even){
        padding-left:30px;
        border-left:1px solid #979797;
      }
    }
    >.col-7{
        padding-right:30px;
        +.col-5{
          padding-left:30px;
          border-left:1px solid #979797;
        }
      }
    }

    .form-grid{
      >.col-6{
        &:nth-child(odd){
          padding-right:15px;
        }
        &:nth-child(even){
          padding-left:15px;
          border-left:0;
        }
      }
      >.col-7{
          padding-right:15px;
          +.col-5{
            padding-left:15px;
            border-left:0;
          }
        }

        .checkbox.column{
          margin-bottom:2rem;
          label{
            color:#fff;
            margin-bottom:1rem;
          }
          input[type='checkbox']{
            position:absolute;
          }
        }
    }

  .col-grid{
    .btn-ghost{
      margin-bottom:0;
    }
  }
  .search-locations{
    .btn-light{
      margin-bottom:0;
    }
  }
  .hospital-list{
    margin-top:6.25rem;
  }
}

.modaal-inner-wrapper{
  vertical-align: top;
  padding:80px 15px;
}


.modaal-content-container{
  padding:162px 15px 30px 15px;
  .h1{
    text-transform: uppercase;
    margin-bottom:100px;
    display:block;
  }
  .h2{
    margin-top:0;
    display:block;
    margin-bottom:2.625rem;
    line-height:3rem;
    &:after{
      content:'';
      height:4px;
      background:$tertiary;
      width:100px;
      display:block;
      margin-top:1.5rem;
    }
  }
  ul{
    margin-bottom:0;
    list-style-type:none;
    padding:0;
    >li{
      margin-bottom:1.5rem;
      &:last-child{
        margin-bottom:0;
      }
    }
  }
}

.modaal-close{
  top:36px;
  position:absolute;
  &:focus,&:hover{
    background:transparent;
    &:before,&:after{
      background:#fff;
    }
  }
  &:focus{
    outline:#009DDB auto 2px;
  }
  &:before,&:after{
    top:6px;
    left:23px;
    width:4px;
    height:36px;
  }
  span{
    clip:initial;
    height:25px !important;
    width:auto !important;
    overflow:visible !important;
    right:50px;
    top:0;
    left:auto;
    bottom:0;
    margin:auto;
    display:block;
    text-transform: uppercase;
  }
}

.modal-content{
  p{
    margin-top:0;
  }
  +div{
    ul{
      margin-top:0;
    }
  }
}

.center-modal{
  padding:12% 15px 30px 15px;
  text-align:center;

    .grid{
      justify-content: center;
      margin-top:3rem;
    }
}

.overlay-grid{
  >div{
    padding-bottom:4rem;
    margin-bottom:0rem;
    border-bottom:1px solid #979797;
    &:nth-last-child(-n + 2){
      margin-bottom:0;
      padding-top:4rem;
      border-bottom:0;
    }
  }
}

@media screen and (max-height: 1000px){
  .modaal-inner-wrapper{
      padding-top:0px;
      padding-bottom:20px;
  }
}

  .site-logo.rev{
    background-size:195px 34px;
    width:195px;
    height:34px;
    margin:0 auto 0 0;
    position:absolute;
    top:43px;
    left:21px;
  }


@media (max-width: 1170px){
  .modaal-inner-wrapper{
    .modaal-container{
        max-width:100%;
    }
  }
  .site-logo.rev{
   left:0;
  }
}

@media (max-width: 991px){
  .hideModalMobile{
    display:none;
    +.modaal-overlay{
      display:none;
    }
  }
}

// @media (max-width:991px){
//   .modaal-wrapper{
//     display:none;
//   }
//   .modaal-overlay{
//     display:none;
//   }
// }


//Mimic global containers for Modaal
$reflex-xs: 576px !default;
$reflex-sm: 768px !default;
$reflex-md: 992px !default;
$reflex-lg: 1170px !default;
$reflex-xlg: 1440px !default;

.modaal-container{
    @media (min-width: $reflex-xs) {
        max-width: $reflex-xs;
    }

    @media (min-width: $reflex-sm) {
        max-width: $reflex-sm;
    }

    @media (min-width: $reflex-md) {
        max-width: $reflex-md;
    }

    @media (min-width: $reflex-lg) {
        max-width: $reflex-lg;
    }

    @media (min-width: $reflex-xlg) {
        max-width: $reflex-xlg;
    }
}
