.accordion-section{
  .accordion{
    background-color:$light;
    margin-bottom:3rem;
    .js-accordion__panel{
      border-bottom:1px solid $grey-600;
      position:relative;
      &:last-child{
        border-bottom:0;
      }
    }
  }
  .accordion__heading {
    margin: 0;
  }

  .accordion__trigger {
    -webkit-box-shadow: none;
    box-shadow: none;
    line-height: 1.5;
    margin:0;
    width: 100%;
    text-align: left;
    padding: 16px 86px 16px 16px;
    font-size: 1.25rem;
    font-weight:600;
    border:0;
    @include button-bg($light);
    color:$primary;
    display: block;
    text-decoration: none;
    text-transform: none;
    &:hover,&:focus{
      &:after{
        right:0;
      }
    }
    &:focus{
      background-color:$light;
    }
    &:after{
      content:'';
      background-repeat: no-repeat;
      background-image: url("../images/icon-plus.svg");
      background-size: 15px;
      display:block;
      position:absolute;
      top:0;
      right:0;
      bottom:auto;
      left:auto;
      margin:auto;
      height:100%;
      width:4.125rem;
      transition:all .3s ease-out;
      background-position:50% 25px;
    }
  }
  .accordion__trigger[aria-expanded='true']{
    color:$grey-600;
    &:after{
      content: '';
      background-image: url("../images/icon-minus-sm.svg");
      background-size: 15px;
      background-repeat: no-repeat;
      background-color: $accent;
      color:$grey-600;
      display:block;
      top:0;
      right:0;
      bottom:auto;
      background-position:50% 32px;
      height:100%;
    }
  }
  .accordion__content {
    display: none;
    padding: 0px 86px 1rem 1rem;
    text-align:left;
    >*{
      color:$grey-600;
      margin-top:0;
      &:last-child{
        margin-bottom:0;
      }
    }
  }
  .btn{
    display:inline-block;
  }
}
