// OVERRIDES ADDED BY MCLAREN

.site-header .site-logo {
  background-image:url('/Uploads/Public/Images/Designs/MHP-Logo.png') !important;
  background-size: 166px !important;
  width: 166px !important;
  height: 69px !important;
}
@media (max-width: 1550px){
  .site-header .site-menu .menu-item {
      padding: 0 25px !important;
 }
}
@media (min-width: 1170px){
  .site-header .site-menu .menu-item {
      padding-left: 20px !important;
 }
}
@media (min-width: 992px){
  .site-header .site-menu .menu-item {
      padding-left: 20px !important;
      width: 150px;
 }
}
.site-header .nav-left {
  padding: 0 15px;
  margin-top: 0;
  position: absolute;
  left: 2%;
  z-index: 9;
  top: 39%;
}
@media (max-width: 991px){
  .site-header .nav-left {
      position: relative !important;
      padding: 20px !important;
      text-align: center;
      left: initial;
      top: initial;
      margin: 0 auto;
      display: inline-block;
 }
}
.site-header .nav-right {
  top: 142px !important;
}
.site-header .menu-wrapper .utility-menu li:first-child {
  flex: auto;
}
@media (min-width: 992px){ 
  .site-header .menu-wrapper .utility-menu:after {
  position: absolute;
  content: '';
  display: block;
  height: 2px;
  width: 100%;
  right: 0;
  bottom: 0;
  background: -webkit-gradient(linear, right top, left top, from(#f0f0f0), color-stop(90%, rgba(240,240,240,0.4)), to(rgba(240,240,240,0.1))) left bottom #fff no-repeat;
  background: linear-gradient(to left, #f0f0f0 0%, rgba(240,240,240,0.4) 90%, rgba(240,240,240,0.1) 100%) left bottom #fff no-repeat;
  background-size: 100% 2px;
}
}
@media (max-width: 1550px){
  ul.site-menu.col-12.order-md-2 {
      padding-left: 20%;
 }
}
