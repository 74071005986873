.physician-results__list{
  margin:0;
  padding:0;
  list-style-type:none;
  >li{
    border-bottom:1px solid $grey-600;
    margin-bottom:3rem;
    padding-bottom:3rem;
  }
}

.provider-results {
  .physician-header,
  .physician-body {
    margin-left:0;
  }
}

.physician-header{
  margin-left:12rem;
  display:flex;
  flex-direction:column;
  padding-bottom:.5rem;
  border-bottom:1px solid $grey-600;
  margin-bottom:2rem;
  .profile-image{
    position:absolute;
    top:0;
    left:15px;
    max-width:160px;
  }
  h2.h4{
    margin: 0;
    &:after{
      content:none;
    }
  }
}

.physician-body{
  margin-left:12rem;
  color:$grey-600;
  ul{
    list-style-type:none;
    padding:0;
    margin-bottom:2rem;
    margin-top:0;
  }
  address{
    .contact-wrapper{
      margin-top:2rem;
      .phone{
        font-weight:600;
      }
      span{
        a{
          margin-left:.5rem;
          color:$grey-600;
        }
      }
    }
    &+address{
      margin-top:2rem;
      &+a{
        margin-bottom:2rem;
      }
    }
    &:last-of-type{
      margin-bottom:2rem;
    }
  }
}

.reviews{
  display:flex;
  flex-wrap:wrap;
  margin-top:.5rem;
  a{
    color:$grey-600;
    font-size:.875rem;
    margin-left:1rem;
  }
}

.button-padding{
  a{
    font-size:1.1875rem;
  }
}

.physician-results {
  &.provider-results {
    .physician-header,
    .physician-body {
      margin-left:0;
      }
  }
}

.provider-results {
  .physician-results__list {
    >li {
      padding-bottom: 7px;
    }
  }
}

@media (max-width:991px){
  .physician-header{
    margin-left:6rem;
    border-bottom:0px;
    margin-bottom:2rem;
    min-height:105px;
    .profile-image{
      width:80px;
      margin-top:0;
    }
    .reviews{
      margin-top:0;
    }
  }
  .physician-body{
    margin-left:0rem;
    margin-top:2rem;
  }
}
