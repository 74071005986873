//Plan-your-visit.nunjucks

.plan-visit{
  background-color:$secondary;
  overflow-x:hidden;
  color:#fff;
  .address-container{
    padding:5rem 0;
    h2{
      margin-bottom:2rem;
      &:after{
        content:initial;
      }
    }
    address{
      font-style:normal;
      margin-bottom:3rem;
      line-height:2rem;
      font-size:1.125rem;
      a:not(.arrow-link){
        color:#fff;
        margin-bottom:1rem;
        display:block;
        font-size:1.5rem;
        font-weight:500;
      }
    }
  }
  .map-container{
    padding:0;
    .map-wrapper{
        position:relative;
        height:100%;
        iframe{
          position:absolute;
          top:0;
          right:0;
          bottom:0;
          left:0;
          width:100%;
          height:100%;
          margin:auto;
        }
    }
  }
}
@media (max-width:991px){
  .plan-visit{
    .map-container{
      display:none;
    }
    .address-container{
      address{
        >div:last-child{
          margin-top:2rem;
        }
      }
      .btn-block{
        padding:.75rem 3.5rem .75rem 1rem;
        width:100%;
        display:block;
        max-width:100%;
        text-align:left;
        &:after{
          content:'';
          height:16px;
          width:13px;
          background-image:url('../images/chevron-right-w.svg');
          background-repeat:no-repeat;
          position:absolute;
          right:20px;
          top:0;
          bottom:0;
          left:auto;
          margin:auto;
          transition: all 0.3s ease-in-out;
        }
      }
      .btn:not(.btn-block){
        @include button-bg($accent);
        width:100%;
        display:block;
        max-width:100%;
        margin-bottom:0;
      }
    }
  }

}

@include desktop{
  .plan-visit{
    .btn{
      margin-bottom:0;
      &:not(.btn-light):not(.btn-block){
        position:absolute;
        right:-37%;
        z-index:1;
        bottom:0;
        width:33%;
      }
    }
  }
}

@include xl-desktop{
  .plan-visit{
    .map-wrapper{
      width:128%;
    }
  }
}

@include xxl-desktop{
  .plan-visit{
    .map-wrapper{
      width:128%;
    }
  }
}

@media (min-width:1600px){
  .plan-visit{
    .map-wrapper{
      width:140%;
    }
  }
}

@media (max-width:1170px){
  .plan-visit{
    >.container{
      max-width:100%;
      .address-container{
        padding-left:15px;
        padding-right:15px;
      }
    }
  }
}
