@mixin gradient($from, $to) {
  /* fallback/image non-cover color */
  background-color: $from;
  background-image: linear-gradient(90deg, $from, $to);
}

@mixin background-opacity($color, $opacity: 0.6) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

@mixin button-bg($bg) {
  background: $bg;
  transition: all 0.3s ease-out;

  &:hover {
    background:darken($bg,6%);
    background-position:right 15px center;
    &:after{
      right:15px;
    }
  }
  &:active {
    background:darken($bg,12%);
    background-position:right 15px center;
  }
  &:focus{
    background:darken($bg,6%);
    background-position:right 15px center;
  }
}

@mixin link-hover($link-color){
  color: $link-color;
  transition: all 0.3s ease-out;

  &:hover{
    color:darken($link-color,6%);
    &:after{
      transition: all 0.3s ease-out;
      background-position: right -3px center;
    }
  }
  &:active{
    color:darken($link-color,6%);
  }
  &:focus{
    color:darken($link-color,6%);
    &:after{
      right:15px;
    }
  }
}
