//Media Query Mixin for shorthand media queries

$mobile-width: 576px;
$tablet-width: 768px;
$desktop-width: 992px;
$xldesktop-width: 1170px;
$xxldesktop-width: 1440px;


//Usage @include mobile {}, @include tablet {}, @include desktop {}, etc.

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}){
    @content;
  }
}

@mixin xl-desktop {
  @media (min-width: #{$xldesktop-width}){
    @content;
  }
}

@mixin xxl-desktop {
  @media (min-width: #{$xxldesktop-width}){
    @content;
  }
}
