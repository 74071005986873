.featured-links{
  .wrapper{
    background-color:$light;
    padding:2rem;
    text-align:left;
    .grid{
      justify-content: left;
      .featured-links__wrapper {
        list-style-type:none;
        margin:0 0 2rem 0;
        flex-wrap:wrap;
        text-align:left;
        display: flex;
        >li{
          flex:0 1 100%;
          max-width:100%;
          margin-bottom:1.5rem;
        }
      }
    }
  }
  .btn{
    margin-bottom:0;
    margin-left:15px;
  }
}


@include tablet{
  .featured-links{
    .wrapper{
      .grid{
        .featured-links__wrapper {
          >li{
            flex:0 1 50%;
            max-width:50%;
            margin-bottom:1.5rem;
          }
        }
      }
    }
  }
}

@include desktop{
  .featured-links{
    .wrapper{
      text-align:center;
      .grid{
          justify-content: center;
        .featured-links__wrapper {
          >li{
            flex:0 1 33.333333%;
            max-width:33.333333%;
            margin-bottom:1.5rem;
          }
        }
      }
    }
    .btn{
      margin-bottom:0;
      margin-left:0px;
    }
  }
}
