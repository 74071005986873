/* Hero Banner */
.hero-banner{
  background:$secondary;
  color:#fff;
  overflow-x:hidden;
  .grid{
   //Div containing image
    .hero-image-container{
      padding:0;
    }
    //Div containing banner links
    .hero-cta-container{
      display:none;
      >div{
        max-width:380px;
        margin:0;
        padding:80px 0;
        >p{
          color:#fff;
          font-weight:300;
        }
        h1{
          text-transform: capitalize;
          margin-top:1.5rem;
        }
      }
    }
  }
  h1,h2{
    margin:0 0 8px 0 ;
  }
  //Button spacing reduced in Banner
  .btn{
    margin-bottom:1rem;
  }

  &.subsidiary{
    .grid{
      .hero-cta-container{
        display:inline-block;
        >div{
          margin:0 auto;
          .phone{
            font-weight:500;
            font-size:1.125rem;
          }
          a{
            color:#fff;
            margin-left:.5rem;
            display:inline-block;
            vertical-align: middle;
            font-size:1.125rem;
          }
        }
      }
    }
  }

  &.mclaren-medicare {
    h1 {
      text-transform: initial;
    }
  }
}

.hero-banner__image{
  background-repeat:no-repeat;
  background-position:top center;
  height:100%;
  position:relative;
  background-size:contain;
  padding:0;
  >div{
    display: flex;
    align-items: left;
    justify-content: space-between;
    padding:25px 15px;
    background-color:rgba(0,68,106,1);
    flex-wrap:wrap;
    margin-top:62%;
    h2.h1{
      text-transform: uppercase;
    }
  }
}

.hero-banner{
  &.subsidiary{
    .hero-banner__image{
      background-position:top left;
    }
  }
}

//Content banner
.content-banner{
  background-color:$secondary;
  padding:3rem 0;
  overflow-x:hidden;
  h1{
    margin:0;
    color:#fff;
  }
  &.featured{
    .background-banner{
      h1,p{
        position:relative;
        color:#fff;
      }
    }
  }
}

@media (max-width:991px){
  .hero-banner{
    .grid{
      .hero-cta-container{
        >div{
          max-width:100%;
          padding:10px 0 15px 0;
          .btn{
            display:none;
          }
        }
      }
    }
    &.subsidiary{
      .grid{
         .hero-cta-container{
           background:#fff;
           >div{
             >p{
               color:$black;
               a{
                 color:$black;
               }
             }
             h1{
               color:$black;
             }
           }
         }
      }
    }
    &.mclaren-medicare {
      .grid {
        .hero-image-container {
          order: 2;
        }
        .hero-cta-container {
          order: 1;
          display: block;
          >div .btn {
            display: block;
          }
        }
      }
    }
  }
  .content-banner{
    padding:1rem 0;
    &:not(.campaign){
      .background-banner{
        background-image:none !important;
      }
    }
    &.featured{
      .container{
        max-width:100%;
        padding:0;
      }
      .background-banner{
        background-repeat:no-repeat;
        background-size:contain;

        margin-top:-15px;
        h1{
          padding:1rem 1rem 0 1rem;
        }
        p{
          display:none;
        }
        .btn{
          margin-left:1rem;
        }
      }
    }
    &.campaign{
      .background-banner{
        padding-top:37%;
        h1{
          padding:1rem;
        }
      }
    }
  }
}

//Subsidiary Hero Banner

@include desktop{
  .hero-banner{
    .grid{
      .hero-cta-container{
        display:inline-block;
        >div{
          h1{
            margin-top:0;
          }
        }
      }
      .hero-image-container{
        border-left:15px solid #fff;
      }
    }
    &.subsidiary{ //Subsidiary banner layout (swapped)
      .grid{
        .hero-image-container{
          border-left:0;
          border-right:15px solid #fff;
        }
      }
    }
    &.mclaren-medicare {
      .grid{
        .hero-cta-container{
          padding-left: 95px;
        }
        .hero-image-container{
          border-left:none;
          .hero-banner__image {
            width: 100%;
          }
        }
        .order-md-2 {
          border-left:15px solid #fff;
        }
      }
    }
  }
  .hero-banner__image{
  background-size:cover;
  >div{
    background-color:rgba(0,68,106,.6);
    margin-top:auto;
    padding:25px 30px;
  }
  }
  .content-banner{
    &.featured{
      padding:0;
      .background-banner{
        height:375px;
        display:flex;
        align-items:center;
        padding-right:50%;
        background-position:center;
        background-repeat:no-repeat;
        background-size:cover;
        margin:0 -6%;
        padding-left:6%;
        position:relative;
        flex-wrap: wrap;
        justify-content: flex-start;
        color:#fff;
        padding-top:75px;
        padding-bottom:75px;
        &:before{
          content:'';
          background-color:rgba(0,68,106,.4);
          display:block;
          position:absolute;
          top:0;
          right:0;
          bottom:0;
          left:0;
          width:100%;
          height:100%;
        }
      }
    }
  }
}




@media (max-width:1170px){
  .hero-banner{
    .wrapper{
      >.container{
        max-width:100%;
      }
    }
  }
}

@include xl-desktop{
  .hero-banner{
    .grid{
      .hero-image-container{
        .hero-banner__image{
          width:128%;
          margin-right:-28%;
          >div{
            h1,h2{
              margin-right:23%;
            }
            a{
              margin-right:23%;
            }
          }
        }
      }
    }
    &.subsidiary{ //Subsidiary banner layout (swapped)
      .grid{
        .hero-image-container{
          .hero-banner__image{
            width:129%;
            margin-left:-29%;
            >div{
              h1,h2{
                margin-left:23%;
                margin-right:0;
              }

              a{
                margin-left:23%;
                margin-right:0;
              }
            }
          }
        }
      }
    }
  }
}
