.whats-on-this-page{
  border:1px solid $grey-600;
  padding:0rem;
  p{
    margin:0;
    padding:1rem;
    font-weight:600;
    &:after{
      content:'';
      display:block;
      position:absolute;
      top:0;
      right:15px;
      bottom:0;
      left:auto;
      margin:auto;
      background-image:url('../images/icon-plus.svg');
      background-repeat:no-repeat;
      background-size:18px;
      height:18px;
      width:18px;
    }
    &.open{
      &:after{
      background-image: url('../images/icon-minus.svg');
      height: 5px;
      }
    }
  }
  ul{
    margin-bottom:0;
    padding-left:0;
    display:flex;
    list-style-type:none;
    flex-wrap:wrap;
    margin-top:0;
    padding:0 1rem;
    &.show{
      padding:0 1rem 1rem;
    }
    li{
      max-width:100%;
      flex:0 1 100%;
      margin-bottom:1rem;
      &:last-child{
        margin-bottom:0;
      }
    }
  }
}

@include desktop{
  .whats-on-this-page{
    padding:2rem;
    p{
      padding:0;
      &:after{
        content:none;
      }
    }
    ul{
      margin-top:1.5rem;
      li{
        max-width:50%;
        flex:0 1 50%;
        &:nth-last-child(-n+2){
          margin-bottom:0;
        }
        a{
          padding-right:1rem;
        }
      }
    }
  }
}
