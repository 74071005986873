
// Patient Tools on the homepage
.patient-tools{
  >.wrapper{
    >.container{
      >.grid{
        >div{
          padding:0;
          .innerWrap{
            padding:2.5rem 1.125rem;
            border-top:2px solid #fff;
            position:relative;
            overflow:hidden;
            .h4{
              z-index:1;
              position:relative;
            }
            &:before{
              content:'';
              background-color:$primary;
              position:absolute;
              width:100%;
              height:100%;
              display:block;
              top:0;
              right:0;
              bottom:0;
              left:0;
              transition: background-color 0.3s ease-out;
              z-index:0;
            }
            span.h4{
              color:#fff;
              margin-bottom:1rem;
              display:block;
            }
            >.image-link{
              margin-top:0;
              margin-bottom:0;
            }
            .btn{
              margin-bottom:0;
            }
          }
        }
      }
    }
    .container > h2, .container > p{
      display:none;
    }
  }
}

.vertical-center{
  display:flex;
  align-items: center;
  >a{
    text-align:center;
  }
}

.patient-tools{
  .image-link{
    .image-link__wrapper{
      display:flex;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(50% - 1px);
      max-width:calc(50% - 1px);
      height:7rem;
      justify-content: center;
      align-items:center;
      font-size:1.125rem;
      position:relative;
      overflow:hidden;
      z-index:0;
      min-height:150px;
      a{
        height:100%;
        width:100%;
        color:#fff;
        z-index:1;
        display:flex;
        align-items:center;
        justify-content: center;
      }
      &:first-child{
        margin-right:1px;
      }
      &:last-child{
        margin-left:1px;
      }
      &:before{
        content:'';
        background-color:$primary;
        position:absolute;
        width:100%;
        height:100%;
        display:block;
        top:0;
        right:0;
        bottom:0;
        left:0;
        transition: background-color 0.3s ease-out;
      }
    }
  }
}

.right-angle-arrow{
  height:45px;
  width:45px;
  background-image:url('../images/triangle-blue.svg');
  background-repeat:no-repeat;
  background-size:45px;
  position:absolute;
  bottom:-3px;
  right:0;
  display:block;
}
.patient-tools{
  .right-angle-arrow{
    &:after{
      content:'';
      background-image:url('../images/chevron-right-w.svg');
      background-repeat:no-repeat;
      background-size:12px 10px;
      width:12px;
      height:10px;
      display:block !important;
      position:absolute;
      top:11px;
      right:-20px;
      bottom:0;
      left:0;
      margin:auto;
    }
  }
}


//Patient Tools Small Component
.patient-tools{
  &.small{
    .image-link{
      margin:0;
    }
  }
}

@include mobile{
  .patient-tools{
    padding-top:0;
  }
}

@include tablet{
  .patient-tools{
    >.wrapper{
      >.container > h2, .container > p{
        display:block;
      }
    }
    .image-link{
      margin-bottom:1.875rem;
      margin-top:3rem;
      .image-link__wrapper{
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(50% - 15px);
        max-width:calc(50% - 15px);
        border-radius:8px;
        &:first-child{
          margin-right:15px;
        }
        &:last-child{
          margin-left:15px;
        }
      }
    }
    .find-a-physician{
      border-radius:8px;
    }
    .er-check-in{
      border-radius:8px;
      margin-bottom:1.875rem;
    }
    &.small{
      .image-link{
        flex-wrap:wrap;
        margin-bottom:1.875rem;
      }
      .image-link__wrapper{
        flex:0 0 100%;
        max-width:100%;
        border-radius:8px;
        &:first-child{
          margin-right:0;
          margin-bottom:1.875rem;
        }
        &:last-child{
          margin-left:0;
        }
      }
    }
  }
}

@include desktop{
  .patient-tools{
    >.wrapper{
      >.container{
        >.grid{
          >div{
            padding:0 15px;
            .innerWrap{
              height:19.6rem;
              padding:4.375rem 1.875rem;
              &:before{
                background-color:rgba(0,101,164,.8);
              }
            }
            .find-a-physician{
              text-align:left;
              img{
                position:absolute;
                bottom:-67px;
                right:7%;
              }
            }
            .er-check-in{
              background-image:url('../images/er-checkin-bg.jpg');
              background-repeat:no-repeat;
              background-size:cover;
            }
            .find-a-physician{
              background-image:url('../images/find-a-physician-bg.jpg');
              background-repeat:no-repeat;
              background-size:cover;
            }
          }
        }
      }
    }
    .er-check-in{
      margin-bottom:0;
    }
    .image-link{
      .image-link__wrapper{
        a{
          font-size:1.75rem;
        }
        &:before{
          background-color:rgba(0,101,164,.8);
        }
        &:hover{
          &:before{
            background-color:rgba(0,82,133,.8);
          }
        }
        &:active {
          &:before{
            background-color:rgba(0,82,133,.8);
          }
        }
        &:focus{
          &:before{
            background-color:rgba(0,82,133,.8);
          }
        }
      }
      .virtual-visit{
        background-image:url('../images/virtual-visit-bg.jpg');
        background-repeat:no-repeat;
        background-size:cover;
      }
      .access-patient-portal{
        background-image:url('../images/access-patient-portal-bg.jpg');
        background-repeat:no-repeat;
        background-size:cover;
      }
    }
    &.small{
      .image-link{
        flex-wrap:wrap;
        margin-bottom:0;
      }
      .image-link__wrapper{
        &:first-child{
          margin-right:0;
          margin-bottom:15px;
        }
        &:last-child{
          margin-left:0;
        }
      }
    }
  }
}
