.sideBar-CTA-links{
  .cta-header{
    h5{
        margin-top:0;
    }
  }
}
.plan-detail {
  .overview {
    padding-bottom: 112px;
    @media (max-width:991px){
      padding-bottom: 1rem;
    }
    main {
      ul {
        @media (max-width:991px){
          padding: 0 0 0 28px;
        }
      }
      p, li {
        color: #60605B;
      }
    }
  }
  .sideBar-CTA-links{
    .cta-header{
      h5{
          font-weight: 700;
      }
    }
  }
}


.cta-body{
  padding-top:.5rem;
  p{
    font-size:.875rem;
    color:$grey-600;
    font-weight:400;
  }
  ul{
    margin-top:0;
  }
}

.cta-footer{
  padding-top:1rem;
}

.item-count{
  font-weight:500;
  color:$primary;
  >.fas{
    margin-right:.25rem;
  }
}

.sideBar-CTA-links{
  .social-row{
    display:flex;
    >li{
      margin-right:3rem;
      margin-bottom:0;
    }
  }
}

.arrow-left {
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right:10px solid $tertiary; 
}