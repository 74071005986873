.patient-navigator{
  background-color:$primary;
  color:#fff;
  padding:20px 0;
  .grid{
    align-items: center;
    .col-auto:nth-child(odd){
      flex:0 1 auto;
      >span{
        white-space: nowrap;
        margin-bottom:1rem;
        display:block;
      }
    }
    .btn{
      margin-bottom:0;
    }
    .form__select-wrap{
      margin-bottom:1rem;
    }
  }
}

@include mobile{
  .patient-navigator{
    .grid{
      .col-auto:nth-child(3), .col-auto:nth-child(4), .col-auto:nth-child(5) {
        flex:0 1 100%;
      }
    }
  }
}

@include tablet{
  .patient-navigator{
    .grid{
      .form__select-wrap{
        margin-bottom:0;
      }
      .col-auto:nth-child(odd){
        >span{
          margin-bottom:0rem;
        }
      }
    }
  }
}


@include desktop{
  .patient-navigator{
    .grid{
      .col-auto:nth-child(odd){
        >span{
          font-size:1.5rem;
        }
      }
    }
  }
}

@include xl-desktop{
  .patient-navigator{
    .grid{
      max-width:70%;
      margin:0 auto;
    }
  }
}
