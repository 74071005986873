//Navigation
.site-header{
  .main-nav{
    background-color:#fff;
  }
  .site-logo{
    background-image:url('../images/mclaren-logo-color.svg');
    background-repeat:no-repeat;
    background-size:110px 19px;
    width:110px;
    height:19px;
    display:block;
    margin:0 auto 0 0;
  }
  .site-menu{
    .menu-link{
      color:#fff;
      font-size:1.125rem;
      padding:0 0 15px 0;
      display:block;
      font-weight:500;
    }
    .menu-item{
      padding-left:0;
      &:last-child{
        .menu-link{
          padding-bottom:0;
        }
      }
    }
  }
  &.plans-header {
    .main-nav {
      .container {
        max-width: 1250px;
        .nav-left {
          align-self: start;
          padding-top: 11px;
          @include mobile{
            padding: 15px;
          }
          .site-logo {
            background-image: url('../images/mclaren-health-plan-logo-color.svg');
            background-size: 176px 62px;
            height: 62px;
            width: 176px;
            @include mobile{
              background-size: 121px 44px;
              height: 44px;
              width: 212px;
            }
          }
        }
        .menu-wrapper{
          .site-menu {
            li {
              &:first-child {
                padding-left: 0;
              }
              &.menu-item {
                display: flex;
                align-items: center;
              }
              .btn {
                font-size: 14px;
                margin: 0;
                padding: 6px 2.7rem 6px 1rem;
                &.arrow-down::after {
                  background-image: url(../images/chevron-down-w.svg);
                  background-size: 20px;
                  height: 20px;
                  right: 12px;
                  width: 20px;
                }
              }
              .menu-link {
                font-weight: 400;
              }
            }
          }
          .utility-menu{
            li {
              display: flex;
              align-items: center;
              padding: 8px 0 8px 25px;
              a{
                padding: 0;
              }
              &.spacer {
                margin-left: 84px;
              }
              &.search-link-desktop{
                &:after {
                  content: '';
                  background-size: 20px;
                  height: 20px;
                  width: 20px;
                  margin: 0 0 0 10px;
                  position: initial;
                }
              }
            }
          }
        }  
      }
    }
  }
  .menu-wrapper{
    .utility-menu{
      font-size:.875rem;
      margin:10px 0;
      li{
        a{
          padding:4px 0 4px 0;
          display:block;
          color:#fff;
          font-weight: 400;
        }
      }
    }
  }
  .menu-wrapper{
    ul{
      list-style-type:none;
      display:flex;
      flex-direction: column;
      margin-top:0;
      margin-bottom:0;
    }
  }
  .nav-left{
    align-self:center;
    padding:25px 15px;
    margin-top:-5px;
  }
  .search-toggle-container{
    padding:25px 15px;
  }
  .search-toggle{
    display:block;
    background-image:url('../images/icon-search.svg');
    background-repeat:no-repeat;
    height:24px;
    width:24px;
    background-size:24px;
    position:absolute;
    top:0;
    right:0;
    left:auto;
    bottom:0;
    margin:auto;
    padding:0 20px;
  }
  .click-to-call{
    display:block;
    background-image:url('../images/icon-phone.png');
    background-repeat:no-repeat;
    height:24px;
    width:24px;
    background-size:24px;
    position:absolute;
    top:0;
    right:0;
    left:auto;
    bottom:0;
    margin:auto;
    padding:0 20px;
  }
  .nav-right{
    background-color:$darkSecondary;
    position:absolute;
    z-index:9000;
    top:103px;
    .menu-wrapper{
      z-index:9000;
    }
    &.show{
      padding-top:.5rem;
    }
  }
  .lbl-header {
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 9px;
    a {
      font-weight: 400;
    }
  }
}

.menu-toggle{
  padding:7px 15px;
  margin:0 -15px;
  color:#fff;
  text-transform: uppercase;
  background-color:$darkSecondary;
  display:block;
  border-bottom:1px solid #fff;
  font-size:1.16rem;
  &:after{
    content:'';
    display:block;
    position:absolute;
    top:0;
    right:15px;
    bottom:0;
    left:auto;
    margin:auto;
    background-image:url('../images/icon-plus-sm.svg');
    background-repeat:no-repeat;
    background-size:18px;
    height:18px;
    width:18px;
  }
}

.site-header .menu-wrapper .utility-menu{
  .search-link-desktop{
    position:relative;
    &:after{
      content:'';
      background-image:url('../images/icon-search.svg');
      background-repeat:no-repeat;
      background-size:16px;
      height:16px;
      width:16px;
      display:inline-block;
      margin-left:.5rem;
      position:absolute;
      top:0;
      right:0;
      left:auto;
      bottom:0;
      margin:auto;
    }
    a{
        padding-right:22px;
    }
  }
}

//Fixed mobile nav

.nav-fixed-mobile{
  position:fixed;
  bottom:0;
  width:100%;
  background-color:$accent;
  color:#fff;
  z-index:7000;
  display:none;
  ul{
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    list-style-type:none;
    padding:9px 0 0 0;
    align-items:center;
    margin:0;
    li{
      flex:0 1 20%;
      a{
        font-weight:300;
        letter-spacing:-.3px;
      }
    }
  }
  a,i{
    color:#fff;
    display:block;
    margin:0 auto;
    text-align:center;
    font-size:13px;
    &:hover,&:focus{
      text-decoration:none;
    }
    i{
      font-size:17px;

    }
  }
  .back-to-top{
    display:block;
    height:43px;
    width:43px;
    display:block;
    border-radius:50%;
    position:relative;
    border:2px solid $accent;
     margin:-27px auto 0px;
    cursor: pointer;
    @include button-bg($secondary);
    >img{
      display:block;
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      margin:auto;
      height:7px;
      width:11px;
    }
  }
}


@include mobile{
  .menu-toggle-container {
    display:block;
  }

  //offset body on mobile for fixed nav-up
  body{
    margin-bottom:50px;
  }

  .nav-fixed-mobile{
    display:block;
  }
}

@media (max-width:991px){
  .search-link-desktop{
    display:none;
  }

}

@include desktop{
  .site-header{
    .site-logo{
      background-size:195px 34px;
      width:195px;
      height:34px;
      margin:0 auto 0 0;
    }
    .menu-wrapper{
      ul{
        flex-direction:row;
        justify-content: flex-end;
      }
    }
    .site-menu{
      .menu-item{
        padding-left:20px;
      }
      .menu-link{
        color:$primary;
        font-size:1.125rem;
        padding:30px 0 25px 0px;
        display:block;
        text-transform: capitalize;
        font-weight:500;
      }
    }
    .search-toggle-container{
      display:none;
    }
    .nav-left{
      padding:0 15px;
      margin-top:0;
    }
    .nav-right{
      background-color:#fff;
      padding-top:0;
      position:static;
      top:0;
      max-height:100%;
      overflow:auto;
      z-index:1;
    }
    .menu-toggle-container {
      display:none;
    }
    .menu-wrapper{
      .utility-menu{
        margin:0;
        &:after{
          position:absolute;
          content:'';
          display:block;
          height:2px;
          width:100%;
          right:0;
          bottom:0;
          background: /* gradient can be an image */
          linear-gradient(
          to left,
          rgba(240,240,240,1) 0%,
          rgba(240,240,240,.4) 90%,
          rgba(240,240,240,.1) 100%
          )
          left
          bottom
          #fff
          no-repeat;
          background-size:100% 2px ;
        }
        li{
          padding-left:25px;
          a{
            padding:8px 0 8px 0;
            display:inline-block;
            color:$primary;
          }
        }
      }
    }
  }
}

@media (min-width:1170px){
  .site-header{
    .site-menu{
      .menu-item{
        padding-left:30px;
      }
    }
  }
}

@media (max-width: 1170px){
  .main-nav{
    >.container{
      max-width:100%;
    }
  }
}

@media (max-width:1125px){
  .site-header{
    .site-menu{
      .menu-link{
        font-size:1rem;
      }
    }
  }
}
