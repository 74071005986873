.cta-bar{
  background-color:$primary;
  padding:1rem 0;
  .grid-row{
    justify-content: center;
    flex-direction:column;
    padding:0;
    .btn{
      width:100%;
      margin-bottom:1rem;
      &:last-child{
        margin-bottom:0;
      }
    }
  }
}

@media (max-width:991px){
  .cta-bar{
    background-color:transparent;
    margin-bottom:1rem;
    .container{
      padding:0;
    }
    .grid-row{
      .btn{
        margin-right:0;
      }
    }
  }
}

@include desktop{
  .cta-bar{
    .grid-row{
      justify-content: flex-end;
      flex-direction:row;
      .btn{
        margin-bottom:0;
        width:auto;
      }
    }
  }
}
