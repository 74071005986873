//Alpha Filters

.alpha-filter{
  display:flex;
  flex-wrap:wrap;
  max-width:500px;
  margin-bottom:6.25rem;
  >a{
    color:#fff;
    @include button-bg($accent)
    text-transform: uppercase;
    margin-right:15px;
    margin-bottom:15px;
    display:flex;
    justify-content: center;
    align-items: center;
    height:40px;
    width:40px;
    font-size:1.25rem;
    &.inactive{
      background-color:transparent;
      color:#30302E;
      &:hover,&:focus{
        background-color:transparent;
      }
    }
  }
}

.physician-search,.services-search{
  .alpha-filter{
    max-width:100%;
    margin-bottom:2rem;
    >a{
      margin-right:5px;
      width:38px;
      height:38px;
      @include button-bg($light);
      color:$primary;
      &.inactive{
        color:$grey-400;
        &:hover,&:focus{
          background-color:$light;
        }
      }
    }
  }
}

.services-search{
  .alpha-filter{
    >a{
      margin-bottom:0rem;
    }
  }
}
