.profile-image{
  margin-bottom:2rem;
  +.btn{
    margin-bottom:0;
  }
}

.location-details__wrapper{
  .location-name{
    color: $grey-600;
    margin-bottom:.5rem;
    margin-top:0;
  }
  .hours{
    margin-bottom:.5rem;
    margin-top:0;
  }
  h6{
    color:$black;
  }
  address{
    font-size:1.125rem;
    color:$grey-600;
    line-height:2rem;
  }
  .contact-wrapper{
    margin-top:1.75rem;
    .phone{
      font-weight:600;

    }
    span{
      >a{
        margin-left:.5rem;
        color:$grey-600;
      }
    }
  }
}
.plans-profile {
  .location-details__wrapper{
    .location-name{
      color: $grey-600;
    }
  }
}

@media (max-width:991px){
  .hours-wrapper{
    margin-top:2rem;
  }
  .profile-image{
    margin-top:2rem;
  }
}
