.sideBar-CTA-links{
  background-color:$light;
  padding:1.5rem;
  display:flex;
  flex-direction:column;
  margin-top:2rem;
  p{
    margin:0;
    font-size:1.125rem;
    font-weight:600;
  }

  ul{
    padding-left:0;
    list-style-type:none;
    margin-bottom:0;
    li{
      margin-bottom:1rem;
      &:last-child{
        margin-bottom:0;
      }
    }
  }
  a{
    display:block;
  }
}
.col-md-4 {
  .sideBar-CTA-links{
    ul {
      li {
        margin-bottom: 24px;
        &:last-child{
          margin-bottom: 7px;
        }
      }
    }
  }
}

.plan-detail {
  .sideBar-CTA-links{
    a {
      font-weight: 700;
      font-size: 18px;
    }
  }
}

@include desktop{
  .overview{
    .sideBar-CTA-links{
      &:first-child{
        margin-top:0;
      }
    }
  }
}
