
.number-list{
  ol {
  list-style: none;
  counter-reset: item;
  }
  li {
    counter-increment: item;
    margin-bottom: 5px;
    p.h6{
      margin-bottom:.5rem;
      font-weight:600;
    }
  }
  li:before {
    content: counter(item);
    background: #fff;
    border:2px solid #E4F1FD;
    border-radius: 100%;
    color: $primary;
    text-align: center;
    display: inline-block;
    left:12px;
    position:absolute;
    height:35px;
    width:35px;
    padding:4px 0;
  }
}

.blockquote{
  p{
    margin-top:0;
    &:last-child{
      margin-bottom:0;
    }
  }
  blockquote{
    margin:0;
    font-weight:300;
    color:$accent;
    font-size:2rem;
    display:block;
    border-right:3px solid $accent;
    line-height:2.5rem;
    padding-right:2rem;
  }
}

.campaign-accordion{
  &.accordion-section{
     .accordion__trigger{
       @include button-bg(#ffffff);
       &:hover,&:focus{
         background-color:#fff;
         &:after{
           right:0;
         }
       }
     }
     .accordion{
       background-color:#fff;
     }
  }
}

.campaign-locations-filter{
  text-align:left;
  .campaign-locations-search{
    .btn{
      margin-bottom:0;
      margin-top:2rem;
    }
  }
  .locations-map.no-search{
    margin-bottom:0;
    .map-wrapper{
      height:450px;
      margin-bottom:2rem;
    }
    .locations-map__list{
      overflow-y: visible;
      display:flex;
      flex-wrap:wrap;
      max-height:100%;
      >li{
        flex:0 1 50%;
        max-width:50%;
        padding:0 1rem;
      }
    }
  }
}

.campaign-profiles{
  text-align:left;
  .profile-results__list{
    list-style-type:none;
    margin:0;
    padding:0;
    >li{
      margin-bottom:4rem;
      display:flex;
      &:last-child{
        border-bottom:0;
      }
    }
  }
  .profile-wrapper{
    max-width:160px;
    margin-right:2rem;
    flex:0 1 33%;
  }
  .profile-card{
    flex:0 1 67%;
    max-width:67%;
  }


  .profile-header{
    h2{
      margin:0;
      font-family:$base-font-family;
      font-size:1.25rem;
      line-height:1.5rem;
      &:after{
        content:none;
      }
    }
    time{
      text-transform:uppercase;
      color:$grey-600;
      font-size:.875rem;
      display:block;
      font-weight:600;
    }
  }

  .profile-body{
    p{
      margin:0;
    }
  }
  .profile-footer{
    margin-top:1rem;
  }
}

.banner-cta{
  background-color:$secondary;
    .background-banner{
      h3,p{
        position:relative;
        color:#fff;
      }
    }
}

.split-cta-color{
  overflow-x:hidden;
  .background-banner{
    background-repeat:no-repeat;
    background-size:cover;
  }
  .split-cta-light{
    background-color:$accent;
    position:relative;
    color:#fff;
    padding:0;
    &:before{
      content:'';
      background-color:rgba(0,101,164,.8);
      display:block;
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      width:100%;
      height:100%;
    }
    h3,p{
      position:relative;
    }
  }
  .split-cta-dark{
    background-color:$secondary;
    position:relative;
    color:#fff;
    padding:0;
    &:before{
      content:'';
      background-color:rgba(0,68,106,.8);
      display:block;
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      width:100%;
      height:100%;
    }
    h3,p{
      position:relative;
    }
  }
}

.form-with-content{
  p{
    &:first-child{
      margin-top:0;
    }
  }
}

.site-footer{
  &.campaign{
    .nav-list{
      display:flex;
      flex-wrap:wrap;
      margin-top:.5rem;
      >li{
        margin-right:2rem;
        margin-bottom:1rem;
        &:last-child{
          margin-right:0rem;
        }
      }
    }
  }
}


@media (max-width:991px){
  .split-content{
    img{
      margin-bottom:2rem;
    }
  }
  .blockquote{
    p{
      margin-top:0;
    }
    blockquote{
      border-right:0;
      border-left:3px solid $accent;
      padding-left:1rem;
      margin-bottom:2rem;
    }
  }
  .campaign-locations-search{
    background-color:$light;
    padding:2rem 0;
    margin:0 -15px;
    .btn{
      margin-top:0;
    }
  }
  .campaign-locations-filter{
    .locations-map .locations-map__list .location-footer .btn{
      margin-top:0;
    }
    .locations-map.no-search{
      margin-bottom:2rem;
      .locations-map__list{
        >li{
          flex:0 1 100%;
          max-width:100%;
          padding:0;
        }
      }
    }
  }
  .campaign-profiles{
    .profile-search{
      padding:0;
      .tool-toggle.open{
        margin-bottom:1rem;
      }
    }
    .profile-wrapper{
      margin-left:auto;
      margin-right:auto;
      margin-bottom:4rem;
    }
    .profile-results__list{
      >li{
        flex-direction:column;
      }
    }
    .profile-card{
      max-width:100%;
    }
  }
  .banner-cta{
    .container{
      padding:0;
    }
    .background-banner{
      background-color:$secondary;
      background-image:none !important;
      padding:2rem 1rem;
      h3,p{
        padding:1rem;
        margin-top:0;
      }
      h3{
        margin-bottom:0;
      }
      .btn{
        margin-left:1rem;
      }
    }
  }
  .split-cta-color{
    .container{
      padding:0;
      .background-banner{
        padding:2.5rem;
        color:#fff;
      }
    }
  }
  .site-footer{
    &.campaign{
      .nav-list{
        justify-content: center;
        margin-top:2.5rem;
        flex-direction:column;
        >li{
          margin-right:0;
          text-align:center;
        }
      }
    }
  }
}

@include desktop{
  .banner-cta{
    padding:0;
    .background-banner{
      height:580px;
      display:flex;
      align-items:flex-start;
      background-position:center;
      background-repeat:no-repeat;
      background-size:cover;
      margin:0 -6%;
      padding-left:10%;
      padding-right:10%;
      position:relative;
      flex-direction: column;
      justify-content: center;
      color:#fff;
      padding-top:75px;
      padding-bottom:75px;
      text-align:center;
      &:before{
        content:'';
        background-color:rgba(0,68,106,.8);
        display:block;
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        width:100%;
        height:100%;
      }
      h3,p,.btn{
        margin-left: auto;
        margin-right:auto;
      }
    }
  }
  .split-cta-color{
    .container{
      max-width:100%;
    }
    .split-cta-light{
      .background-banner{
        margin-left:-6%;
        padding-left:20%;
        padding-right:4rem;
        padding-top:4rem;
        padding-bottom:4rem;
      }
    }
    .split-cta-dark{
      .background-banner{
        margin-right:-6%;
        padding-right:20%;
        padding-left:4rem;
        padding-top:4rem;
        padding-bottom:4rem;
      }
    }
  }
}

@include xxl-desktop{
  .split-cta-color{
    .split-cta-light{
      .background-banner{
        margin-left:0%;
      }
    }
    .split-cta-dark{
      .background-banner{
        margin-right:0%;
      }
    }
  }
}
