// Labels

label{
  font-size:1rem;
  color:$grey-600;
  margin-bottom:.5rem;
  display:block;
  letter-spacing:-.2px;
}

.form-grid{
  [class*="col-"]{
    padding-left:5px;
    padding-right:5px;
  }
}

//Forms -- Input
.form__input-wrap{
  margin-bottom:1.5rem;
  position:relative;
  border-radius:4px;
  overflow:hidden;
}

.form__input{
  padding:.75rem 1rem .75rem 1rem;
  border-radius:4px;
  border:1px solid $grey-600;
  transition: all 0.3s linear;
  display:block;
  width:100%;
  transition: 0.4s;
  outline:none;
  &:active{
    border:1px solid $primary;
    color:$grey-800;
  }
  &:focus{
    border:1px solid $primary;
    outline:$primary;
    &::placeholder{
      color:$grey-800;
    }
  }
  &:required:valid{
    border:1px solid $positive;
  }
  &:invalid{
    border:1px solid $negative;
  }
  &:required:focus:valid{
    border:1px solid $positive;
  }
  &:focus:invalid{
    border:1px solid $negative;
  }
}


  .form__input-wrap[class*="icon-"]{
    .form__input{
      padding:.75rem 3.5rem .75rem 1rem;
    }
  }

  .form__input-wrap.clearable.icon-search{
    .form__input{
      padding:.75rem 3.5rem .75rem 3rem;
    }
  }

  .form__input-wrap.clearable{
    .form__input{
      padding:.75rem 3.5rem .75rem 1rem;
    }
  }


/* Clearable text inputs */
.clearable{
  position: relative;
}
.clearable input[type=search]{
  width: 100%;
}
.clearable__clear{
  background:url('../images/icon-close.svg');
  background-repeat:no-repeat;
  background-position:center center;
  height:100%;
  width:25px;
  padding:0 25px;
  display: none;
  position: absolute;
  right:0;
  top:0;
  user-select: none;
  border:0;
  cursor: pointer;
}
.clearable input::-ms-clear {  /* Remove IE default X */
  display: none;
}

/* Disabled inputs */
.form__input{
  &:disabled{
    opacity:.6;
    background:$grey-200;
    color:$grey-600;
    border:1px solid $grey-600;
    cursor:not-allowed;
  }
  &.simple-border{
    &:disabled{
      background:none;
      border-bottom:1px solid $grey-600;
    }
  }
}

/* Input Icons */
.icon-email{
  .form-icon:after{
    content:'';
    background-image:url('../images/icon-email-grey.svg');
    background-repeat:no-repeat;
    background-position:center;
    background-size:19px 22px;
    height:19px;
    width:22px;
    position:absolute;
    right:12px;
    top:0;
    bottom:0;
    margin:auto;
  }
  &.input-has-value{
    .form__input{
      &+.form-icon:after{
        content:'';
        background-image:url('../images/icon-email-active.svg');
        background-repeat:no-repeat;
        background-position:center;
      }
      &:invalid{
        &+.form-icon:after{
          background-image:url('../images/icon-email-error.svg');
        }
      }
    }
  }
}

.icon-user{
  .form-icon:after{
    content:'';
    background-image:url('../images/icon-user-grey.svg');
    background-repeat:no-repeat;
    background-position:center;
    background-size:19px 22px;
    height:19px;
    width:22px;
    position:absolute;
    right:12px;
    top:0;
    bottom:0;
    margin:auto;
  }
  &.input-has-value{
    .form__input{
      &+.form-icon:after{
        content:'';
        background-image:url('../images/icon-user-active.svg');
        background-repeat:no-repeat;
        background-position:center;
      }
      &:invalid{
        &+.form-icon:after{
          background-image:url('../images/icon-user-error.svg');
        }
      }
    }
  }
}

.icon-password{
  .form-icon:after{
    content:'';
    background-image:url('../images/icon-password-grey.svg');
    background-repeat:no-repeat;
    background-position:center;
    background-size:22px 22px;
    height:22px;
    width:22px;
    position:absolute;
    right:12px;
    top:0;
    bottom:0;
    margin:auto;
  }
  &.input-has-value{
    .form__input{
      &+.form-icon:after{
        content:'';
        background-image:url('../images/icon-password-active.svg');
        background-repeat:no-repeat;
        background-position:center;
      }
      &:invalid{
        &+.form-icon:after{
          background-image:url('../images/icon-password-error.svg');
        }
      }
    }
  }
}

.icon-search{
  input[type="search"]{
    background-image:url('../images/icon-search-grey.svg');
    background-repeat:no-repeat;
    background-position: left 15px center;
    padding-left:50px;
  }
}





/* Input focus border effect - simple border */
.form__input.simple-border{
  border:0;
  padding:.75rem 3.5rem .75rem 1rem;
  border-bottom:1px solid $grey-600;
  border-radius:0;
  ~.focus-border{
    position:absolute;
    bottom:0;
    left:0;
    width:0;
    height:2px;
    background-color:$primary;
    transition:0.4s;
    &:before,&:after{
      display:none;
    }
  }
  &:focus{
    ~.focus-border{
      width:100%;
      transition:0.4s;
    }
  }
}

/* Select Form */
.form__select-wrap{
  margin-bottom:1.5rem;
  position:relative;
}
/*Replacement for select element*/
.select2-container--default{
  position:relative;
  width:auto;
  display:block;
  width:100% !important;
  .select2-selection--single{
    height:50px;
    transition:0.4s;
    border:1px solid $grey-600;
    &:focus{
      outline:none;
      border:1px solid $primary;
    }
    .select2-selection__rendered{
      padding:.75rem 3.5rem .75rem 1rem;
      line-height:26px;
      text-align:left;
    }
    .select2-selection__arrow{
      height:50px;
      right:20px;
    }
  }
  .select2-selection--multiple{
    min-height:50px;
    // transition:0.4s;
    position:relative;
    background-image:url('../images/chevron-down-blue.svg');
    background-repeat:no-repeat;
    background-position:calc(100% - 20px) 18px;
    background-size:20px;
    border:1px solid $grey-600;
    &[aria-expanded="true"]{
      background-image:url('../images/chevron-up-blue.svg');
      background-repeat:no-repeat;
      background-position:calc(100% - 20px) 18px;
      background-size:20px;
    }
    .select2-selection__rendered{
      padding:0 3rem .65rem 1rem;
      display:block;
    }
    .select2-selection__choice{
      padding:2px 9px;
      margin-top:9px;
      margin-right:9px;
      color:$grey-700;
      margin-bottom:0px;
      background-color:$accent;
      color:#fff;
      border:0px;
    }
    .select2-selection__choice__remove{
      margin-right:9px;
      color:#fff;
    }
  }
}

.select2-container .select2-search--inline .select2-search__field{
  margin-top:11px;
  max-width:100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li{
  max-width:100%;
}

.modaal-container .select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-search__field{
  width:200px !important;
}

.select2-search .select2-search--inline{
  max-width:100%;
}

.select2-container--default.select2-container--focus{
  .select2-selection--multiple{
    border:1px solid $primary;
  }
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single{
  border:1px solid $primary;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b{
  border:0;
  background-image:url('../images/chevron-down-blue.svg');
  background-repeat:no-repeat;
  background-position:center;
  height:20px;
  width:20px;
  background-size:20px;
  left:0;
  right:0;
  bottom:0;
  top:0;
  margin:auto;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b{
  border:0;
  background-image:url('../images/chevron-up-blue.svg');
  background-repeat:no-repeat;
  background-position:center;
  height:20px;
  width:20px;
  background-size:20px;
  left:0;
  right:0;
  bottom:0;
  top:0;
  margin:auto;
}

.select2-container--default .select2-results__option--highlighted[aria-selected]{
  background-color:$primary;
}

.select2-dropdown{
  z-index:99999;
}

//Switch
[data-action='aria-switch'] {
  background: $grey-400;
  border-radius: 32px;
  display: inline-block;
  position: relative;
  -webkit-transition: background 0.1s ease-in-out;
  -o-transition: background 0.1s ease-in-out;
  transition: background 0.1s ease-in-out;
  border: 0;
  width: 40px;
  min-height: 24px;
  overflow: hidden;
  clear: both;
  float: left;
}
[data-action='aria-switch']:focus {
  background: $grey-400;
}
[data-action='aria-switch']:hover {
  background: $grey-400;
}
[data-action='aria-switch']:not([disabled]):hover, [data-action='aria-switch']:not([disabled]):focus {
  border: 0;
}
[data-action='aria-switch'] span {
  border-radius: 32px;
  display: inline-block;
  font-weight: bold;
  pointer-events: none;
  text-transform: uppercase;
  position: absolute;
}
[data-action='aria-switch'][aria-checked='false'] span {
  background: #ffffff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  top: 2px;
  left: 2px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
[data-action='aria-switch'][aria-checked='true'] span {
  background: #ffffff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  top: 2px;
  left: 18px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
[data-action='aria-switch'][aria-checked='true'] {
  background: $primary;
}

.optional-label {
  display: inline-block;
  margin-left:.5rem;
  color:$grey-600;
}

.no-js [data-action='aria-switch']:not([aria-checked]) span {
  background: #ffffff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  top: 2px;
  left: 43px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

/*Checkbox */
fieldset{
  border:0;
  padding:0;
  margin:0;
}

.checkbox.row label {
  display: block;
  float: left;
  padding-bottom: 1rem;
  clear: none;
}

.checkbox.column label {
  display: block;
}


.checkbox input[type='checkbox'] {
  opacity: 0;
}

.checkbox label {
  position: relative;
  padding: 0 3rem 0 2rem;
}

.checkbox label::before,
.checkbox label::after {
  position: absolute;
  content: '';
  display: inline-block;
}

.checkbox label::before {
  height: 22px;
  width: 22px;
  left: 0;
  top: 1px;
  background: #fff;
  border: 1px solid $grey-600;
  border-radius:4px;
}

.checkbox label:hover::before{
  border:1px solid $primary;
}

.checkbox label:disabled:hover:before{
  border:1px solid $grey-600;
}

.checkbox label::after {
  left: 7px;
  top: 3px;
  width: 8px;
  height: 14px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox input[type='checkbox'] + label::after {
  content: none;
}

.checkbox input[type='checkbox']:checked + label::after {
  content: '';
}

.checkbox input[type='checkbox']:checked + label::before {
  background: $primary;
  border-color:$primary;
}

.checkbox input[type='checkbox']:focus + label::before {
  outline: auto 2px Highlight;
  outline: auto 5px -webkit-focus-ring-color;
  border-color:$primary;
}

.checkbox input[type='checkbox']:disabled + label{
  color:#A5A5A5;
}

.checkbox input[type='checkbox']:disabled + label::before{
  background:$grey-400;
  border-color:#8d8d80;
}


/* Radio Buttons */

.radio.row label {
  display: block;
  float: left;
}

.radio.column label {
  display: block;
}

.radio input[type='radio'] {
  opacity: 0;
}

.radio label {
  position: relative;
  padding: 0 3rem 0 2rem;
}

.radio label::before,
.radio label::after {
  position: absolute;
  content: '';
  display: inline-block;
}

.radio label::before {
  height: 22px;
  width: 22px;
  left: 0;
  top: 1px;
  background: #ffffff;
  border-radius: 50%;
  border: 1px solid $grey-600;
}

.radio label::after {
  left: 5px;
  top: 6px;
  width: 10px;
  height: 10px;
  border: 6px solid $primary;
  border-radius: 50%;
}

.radio label:hover:before{
  border:1px solid $primary;
}

.radio input[type='radio'] + label::after {
  content: none;
}

.radio input[type='radio']:checked + label::after {
  content: '';
}

.radio input[type='radio']:checked + label:before{
  border-color:$primary;
}

.radio input[type='radio']:focus + label::before {
  outline: auto 2px Highlight;
  outline: auto 5px -webkit-focus-ring-color;
}

.radio input[type='radio']:disabled + label{
  color:#A5A5A5;
}

.radio input[type='radio']:disabled + label::before{
  border-color:#8d8d80;
}

.radio input[type='radio']:disabled + label::after {
  content:'';
  left: 5px;
  top: 6px;
  width: 10px;
  height: 10px;
  border: 6px solid #8d8d80;
  border-radius: 50%;
}

/* Radio Row Tabs*/
.radio.row{
  display:flex;
  flex-wrap:wrap;
  input[type='radio']{
    height:0;
    width:0;
  &:checked{
      &+label{
        background-color:$secondary;
        &:before{
          content:none;
        }
        &:after{
          content:none;
        }
      }
    }
  }
  label{
    background-color:$primary;
    color:#fff;
    flex:0 1 25%;
    max-width:25%;
    padding:.8rem 0;
    text-align:center;
    font-size:.875rem;
    border-right:1px solid #015b93;
    &:before{
      content:none;
    }
    &:after{
      content:none;
    }
    &:first-of-type{
      border-top-left-radius:4px;
      border-bottom-left-radius:4px;
    }
    &:last-of-type{
      border-top-right-radius:4px;
      border-bottom-right-radius:4px;
      border-right:0;
    }
    &:hover,&:focus{
      background-color:#015b93;
    }
  }
}

@media (max-width:991px){
  .radio.row{
    margin-bottom:1rem;
  }
  .form-grid{
    [class*="col-"]{
      padding-left:15px;
      padding-right:15px;
    }
  }
}
