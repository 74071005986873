.search-search{
  background-color:$light;
  padding:2.5rem 0;
}

/*Search Result Styles*/
.filter-toggle{
  .locations-map{
    margin-bottom:0;
  }
  .locations-map__list{
    overflow-y: visible;
    display:flex;
    flex-wrap:wrap;
    max-height:100%;
    list-style-type:none;
    margin:0;
    padding:0;
    color:$grey-600;
    >li{
      flex:0 1 50%;
      max-width:50%;
      padding:0 1rem;
    }
  }
  .search-global__results{
    padding:0;
    .events-results__list{
      margin-bottom:0;
    }
    ul{
      list-style-type:none;
      margin-bottom:0;
      padding:0;
    }
    .links-results__list{
      >li{
        margin-bottom:1.5rem;
      }
    }
    >.grid{
      >div:nth-child(2){
         .global-result-category{
           margin-top:2rem;
           padding-top:2rem;
           border-top:1px solid $grey-600;
         }
      }
    }
  }
}


@media (max-width:991px){
  .search-search{
    padding:0;
    .tool-toggle.open{
      margin-bottom:1rem;
    }
  }
  .search__wrapper{
    .btn{
      margin-bottom:0;
    }
  }
  .filter-toggle{
    .locations-map__list{
      >li{
        flex:0 1 100%;
        max-width:100%;
        padding:0;
      }
    }
  }
}
@include desktop{
  .search-search{
    .form__input-wrap,.btn{
      margin-bottom:0;
    }
  }
}
