.page-tools{
  margin-top:1.5rem;
  .wrapper{
    border-bottom:1px solid $grey-600;
    padding-bottom:.5rem;
  }
  .col-12{
    display:flex;
    align-content: center;
    .arrow-link{
      flex: 0 1 auto;
    }
    .icon-share{
      flex:0 1 auto;
      margin-left:auto;
      margin-right:2rem;
      background-color:transparent;
      border:0;
      background-image:url('../images/icon-share.svg');
      background-repeat:no-repeat;
      background-size:20px;
      height:20px;
      width:20px;
    }
    .icon-print{
      flex:0 1 auto;
      background-color:transparent;
      border:0;
      background-image:url('../images/icon-print.svg');
      background-repeat:no-repeat;
      background-size:22px 20px;
      height:22px;
      width:20px;
    }

    .tool-link{
      flex: 0 1 auto;
      margin-left:auto;
      margin-right:2rem;
      padding-right:2rem;
      border-right:1px solid $grey-600;
      &+.icon-share{
        margin-left:0;
      }
    }

    .icon-share,.icon-print{
      &:hover,&:focus{
        opacity:.8;
        cursor: pointer;
      }
    }
  }
}

@media (max-width:991px){
  .page-tools{
    .wrapper{
      border-bottom:0px;
      padding-bottom:0;
    }
    .col-12{
      .icon-print,.tool-link{
        display:none;
      }
      .tool-link{
        &+.icon-share{
          margin-left:auto;
        }
      }
      .icon-share{
        margin-right:0;
      }

    }
  }
}
