
// Events module on the homepage
.events-list{
  .events-list__categories{
      list-style-type:none;
      text-align:left;
      padding-left:0;
      margin-bottom:3rem;
      li{
        padding-bottom:.75rem;
        margin-bottom:.75rem;
        border-bottom:1px solid $grey-200;
      }
  }
}

.events-list__categories{
  display:none;
}

@include tablet{
  .events-list{
    .grid{
      >div:nth-of-type(2),div:nth-of-type(3){
        margin-top:4rem;
      }
    }
  }
  .events-list__categories{
    display:block;
  }
  .event-list__categories--mobile{
    display:none;
  }
}

@include mobile{
  .events-list{
    .btn{
      margin-bottom:0;
    }
  }
}
