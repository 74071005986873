//
// Color system
//

$white:    #fff !default;
$grey-200: #DBDBD6 !default; // Lighter Grey
$grey-400: #A6A699 !default; // Light Grey
$grey-600: #60605B !default; // Grey
$grey-700: #30302E !default; // Medium Grey
$grey-800: #000A12 !default; // Dark Grey
$black:    #000 !default;

$greys: () !default;
$greys: map-merge((
  "200": $grey-200,
  "400": $grey-400,
  "600": $grey-600,
  "800": $grey-800
), $greys);

$primary:       #0065A4 !default; //McLaren Blue
$secondary:     #00446a !default; //McLaren Dark Blue
$tertiary:      #8DC8E8 !default; //McLaren Light Blue
$accent:        #009DDB !default; //McLaren Bright Blue - Only used with font 18.66px or larger
$darkSecondary: #00263B !default;  // McLaren Dark Blue
$positive:      #0A8A19 !default;
$active:        #0065a4 !default;
$warning:       #B98B0E !default;
$negative:      #A40036 !default;
$light:         #f8f8f8 !default; //Snow - Lightest Color
$dark:          $grey-800 !default;

$theme-colors: () !default;
$theme-colors: map-merge((
  "primary":     $primary,
  "secondary":   $secondary,
  "tertiary":    $tertiary,
  "bright-blue": $accent,
  "positive":    $positive,
  "active":      $active,
  "warning":     $warning,
  "negative":    $negative,
  "light":       $light,
  "dark":        $dark
), $theme-colors);
// stylelint-enable
