.accordion-tabs{
  background-color:transparent;
}

.accordion-featured-links{
  background-color:$light;
  .tabs-tab-list{
    display:none;
  }
  .accordion-tabs{
    margin:0 -15px;
  }
  .content{
    padding-top:0;
    padding-left:15px;
    padding-right:15px;
    li{
      margin-bottom:2rem;
      text-align:left;
      padding-right:2rem;
      h4{
        font-size:1.17rem;
        font-weight:600;
        margin-bottom:0rem;
        color:$grey-600;
      }
      a{
        display:block;
        margin-bottom:.25rem;
        &:last-child{
          margin-bottom:0;
        }
      }
    }
  }
  .tabs-allowed .tabs-panel{
    border-top:0;
  }
}

@include desktop{
  .accordion-featured-links{
    .accordion-tabs{
      margin:0;
    }
    .content{
      padding-left:0;
      padding-right:0;
    }
  }
}
