.video-search{
  background-color:$light;
  padding:2.5rem 0 1.5rem 0;
}

.video-results__list{
  list-style-type:none;
  margin:0;
  padding:0;
  >li{
    margin-bottom:2rem;
    padding-bottom:2rem;
    border-bottom:1px solid $grey-600;
    display:flex;
    &:last-child{
      border-bottom:0;
    }
  }
  .video-wrapper{
    max-width:350px;
    margin-right:2rem;
    flex:0 1 33%;
  }
  .video-card{
    flex:0 1 67%;
  }
}

.video-header{
  h2{
    margin:0;
    font-family:$base-font-family;
    font-size:1.25rem;
    line-height:2rem;
    &:after{
      content:none;
    }
  }
  time{
    text-transform:uppercase;
    color:$grey-600;
    font-size:.875rem;
    display:block;
    font-weight:600;
  }
}

.video-body{
  p{
    margin:0;
  }
}
.video-footer{
  margin-top:1rem;
}

@media (max-width:991px){
  .video-search{
    padding:0;
    .tool-toggle.open{
      margin-bottom:1rem;
    }
  }
  .video-wrapper{
    margin-right:0;
    margin-bottom:2rem;
  }
  .video-results__list{
    >li{
      flex-direction:column;
      p{
        display:none;
      }
    }
  }
}
@include desktop{
  .video-search{
    .btn{
      margin-bottom:0;
    }
  }
}
