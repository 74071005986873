.a11y-tip{
  display:inline-block;
  position:relative;
  vertical-align: middle;
  margin-left:.35rem;
}

.a11y-tip__trigger{
  display:block;
  height:20px;
}

[role='tooltip'].a11y-tip__help{
  background: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  left: 50%;
  margin-top: 16px;
  max-width: 400px;
  opacity: 0;
  padding: 0.5rem 1rem;
  pointer-events: none;
  position: absolute;
  text-align: left;
  top: 100%;
  -webkit-transform: translate(-50%, 10px);
  -ms-transform: translate(-50%, 10px);
  transform: translate(-50%, 10px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: opacity 0.2s 1s ease-in-out;
  -o-transition: opacity 0.2s 1s ease-in-out;
  transition: opacity 0.2s 1s ease-in-out;
  visibility: hidden;
  width: 400px;
  z-index: 10;
  font-size:.875rem;
  text-transform:initial;
}

[role='tooltip'].a11y-tip__help::after{
  border: inset 10px;
  display: inline-block;
  height: 0;
  speak: none;
  width: 0;
  border-color: transparent transparent rgba(0, 0, 0, 0.9) transparent;
  border-bottom-style: solid;
  bottom: 100%;
  content: '';
  left: 50%;
  margin-top: 6px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px);
  -ms-transform: translate(-50%, 10px);
  transform: translate(-50%, 10px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: opacity 0.2s 1s ease-in-out;
  -o-transition: opacity 0.2s 1s ease-in-out;
  transition: opacity 0.2s 1s ease-in-out;
  visibility: hidden;
  z-index: 10;
}

.a11y-tip__trigger[aria-describedby]:hover + .a11y-tip__help, .a11y-tip__trigger[aria-describedby]:not(.a11y-tip__trigger--toggle):focus + .a11y-tip__help{
  transform:translate(-50%, 0);
  opacity:1;
  pointer-events:auto;
  visibility: visible;
}

.a11y-tip__trigger[aria-describedby]:hover + .a11y-tip__help, .a11y-tip__trigger[aria-describedby]:hover + .a11y-tip__help::after, .a11y-tip__trigger[aria-describedby]:not(.a11y-tip__trigger--toggle):focus + .a11y-tip__help, .a11y-tip__trigger[aria-describedby]:not(.a11y-tip__trigger--toggle):focus + .a11y-tip__help::after, .a11y-tip__trigger[aria-describedby][aria-expanded='true'] .a11y-tip__help, .a11y-tip__trigger[aria-describedby][aria-expanded='true'] .a11y-tip__help::after{
  transform:translate(-50%, 0);
}

.a11y-tip__trigger[aria-describedby]:hover + .a11y-tip__help, .a11y-tip__trigger[aria-describedby]:hover + .a11y-tip__help::after, .a11y-tip__trigger[aria-describedby]:not(.a11y-tip__trigger--toggle):focus + .a11y-tip__help, .a11y-tip__trigger[aria-describedby]:not(.a11y-tip__trigger--toggle):focus + .a11y-tip__help::after, .a11y-tip__trigger[aria-describedby][aria-expanded='true'] .a11y-tip__help, .a11y-tip__trigger[aria-describedby][aria-expanded='true'] .a11y-tip__help::after{
  opacity:1;
  pointer-events:auto;
  visibility: visible;
}

@media (max-width:991px){
  .pre-header{
    position:relative;
    .a11y-tip{
      position:initial;
    }
    [role='tooltip'].a11y-tip__help{
      width:240px;
      left:0;
      top:20px;
    }
    [role='tooltip'].a11y-tip__help::after{
      left:88%;
    }
    .a11y-tip__trigger[aria-describedby]:hover+.a11y-tip__help, .a11y-tip__trigger[aria-describedby]:hover+.a11y-tip__help::after, .a11y-tip__trigger[aria-describedby]:not(.a11y-tip__trigger--toggle):focus+.a11y-tip__help, .a11y-tip__trigger[aria-describedby]:not(.a11y-tip__trigger--toggle):focus+.a11y-tip__help::after, .a11y-tip__trigger[aria-describedby][aria-expanded='true'] .a11y-tip__help, .a11y-tip__trigger[aria-describedby][aria-expanded='true'] .a11y-tip__help::after{
      transform:translate(0%,0);
    }
  }
}
