//
// Tabs
//

$card-border: 2px solid $grey-200;
$card-border-radius:8px;

.card-header-img {
    overflow: hidden;
    padding: 0 0 $reflex-grid-spacing;
    border-top: $card-border;
    border-left: $card-border;
    border-right: $card-border;
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
}

.card-body {
    @include flex(1, 1, auto);
    overflow: hidden;
    padding: 0 $reflex-grid-spacing;
    border-left: $card-border;
    border-right: $card-border;
    text-align:left;

    &:first-child {
        padding-top: $reflex-grid-spacing;
        border-top: $card-border;
        border-top-left-radius: $card-border-radius;
        border-top-right-radius: $card-border-radius;
    }

    &:only-child {
        padding-bottom: $reflex-grid-spacing;
        border-bottom: $card-border;
        border-bottom-left-radius: $card-border-radius;
        border-bottom-right-radius: $card-border-radius;
    }
    h3{
      font-family:$base-font-family;
      text-align:left;
      margin:0 0 .5rem 0;
      font-weight:500;
      font-size:1.5rem;
      line-height:2rem;
    }
    time{
      font-size:.875rem;
      text-transform: uppercase;
      color:$grey-600;
      font-weight:500;
    }
    span{
      font-size:.875rem;
      text-transform: uppercase;
      color:$grey-600;
    }
    p{
      margin-top:1.25rem;
    }
}

.spacer-lg main .card-body h3{
  margin: 0 0 .5rem 0;
}

.video-wrapper{
  &+.card-body{
    padding-top:1rem;
  }
}

.direction-row {
    .card-bg-img {
        display: block;
        float: left;
        width: 40%;
        max-width: 40%;
        min-height: 150px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        border-top-left-radius: $card-border-radius;
        border-top-right-radius: 0;
        border-bottom-left-radius: $card-border-radius;

        &::after {
            content: '';
            display: block;
            padding-top: 60%;
        }
    }

    .card-body {
        @include display-flex();
        @include flex-direction(column);
        @include justify-content-center();
        @include flex(1, 1, 60%);
        padding: $reflex-grid-spacing;
        border-top: $card-border;
        border-right: $card-border;
        border-left: 0;
        border-bottom: $card-border;
        border-top-right-radius: $card-border-radius;
        border-bottom-right-radius: $card-border-radius;

        > :last-child {
            margin-bottom: 0;
        }
    }
}

.card-footer {
    overflow: hidden;
    @include display-flex();
    @include align-items(center);
    @include justify-content-space-between();
    margin-top: auto;
    padding: $reflex-grid-spacing;
    border-bottom: $card-border;
    border-left: $card-border;
    border-right: $card-border;
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
}

.card-center {
    display: block;
    overflow: hidden;
    @include display-flex();
    @include flex-direction(column);
    @include justify-content-center();
    @include flex(1, 1, auto);
    text-align: center;
    padding: $reflex-grid-spacing;
    border: $card-border;
    border-radius: $card-border-radius;

    > :last-child {
        margin-bottom: 0;
    }
}

.card-footer-link {
    display: block;
    overflow: hidden;
    margin-top: auto;
    padding: 0 15px 15px;
    border-bottom: $card-border;
    border-left: $card-border;
    border-right: $card-border;
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
    text-align: left;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
        // color: $colour-text;
        // border-color: darken($colour-col-padding, 20%);
        // background-color: darken($colour-col-padding, 20%);
        outline: none;
    }
}

.card-img-circle {
    width: 60%;
    max-width: 60%;
    border-radius: 50%;
    margin: $reflex-grid-spacing auto;
}

.card {
  &.cards-basic-with-transparency {
    background-color: rgba($color: #ffffff, $alpha: .8);
  }
}

.split-33 {
  
  &.section-with-background-img {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    padding: 6.9375em 0;
    margin: 0;

    @include mobile {
      background-color: #F8F8F8;
      background-image: none !important;
      padding: 5px 0 26px;
    }

    .card {
      max-width: 350px;
      padding: 2.5rem 2.5em 2.375rem;
      @include mobile {
        max-width: initial;
        margin: 0 1.25em;
        padding: 1.5625em 0 2.125em;
        border-bottom: 1px solid #979797;
        background-color: transparent;
      }

      img {
        width: 100%;
        margin-bottom: 0.75em;
        @include mobile {
          margin-bottom: 1.9375rem;
        }
      }

      h2 {
        margin: 0;
        @include mobile {
          margin: 12px 0 0;
        }

        &:after {
          margin: 1.375rem 0 0;
          width: 2.5rem;
          @include mobile {
            margin: 19px 0 0;
          }
        }
      }

      p {
        margin: 1.9375rem 0 1.5625em;
        color: $grey-600;
        @include mobile {
          margin: 22px 0 34px;
        }
      }

      a {
        font-size: 1.125em;
        font-weight: 700;
        letter-spacing: 0.266571px;
      }
    }

    &.accordeon-mob {
      @include mobile{
        display: block;
      }
      .tabs-tab-list {
        display: none;
        @include mobile{
          display: block;
        }
      }
      .tabs-panel {
        &:first-child {
          .card {
            margin-left: 0;
          }
        }
        &:last-child {
          .card {
            @include mobile {
              border-bottom: none;
            }
          }
        }
        &.is-open{
          border-bottom: none;
        }
        .card {
          margin: 0 1rem;
          @include mobile {
            margin: 0;
          }
        }
      }
      .accordeon-trigger {
        display: none;
        @include mobile{
          display: flex;
          font-weight: 700;
          padding: 21px 20px 19px 15px;
        }
      }
      .content {
        margin-top: 0;
        padding: 0;
      }
    }
  }

  > .tabs-panel {
    display: flex;
    border-top: none;
    @include mobile {
      display: block;
      padding: 0 1.875em;
    }
    &.is-hidden {
      display: inherit;

      .content {
        display: inherit;
        @include mobile {
          display: none;
        }
      }
    }
  }
}

.hide-on-desktop {
  display: none;
  @include mobile {
    display: inherit;
  }
}

.hide-on-mobile {
  display: inherit;
  @include mobile {
    display: none;
  }
}

@include mobile{
  .card-header-img{
    display:none;
  }
  .card-body{
    border:0;
    padding-left:0;
    padding-right:0;
    p{
      display:none;
    }
    &:first-child{
      border:0;
    }
  }
  .card-footer-link{
    border:0;
    padding-left:0;
    padding-right:0;
    padding-top:15px;
  }
}
