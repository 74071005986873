.events-search{
  background-color:$light;
  padding:2.5rem 0 1.5rem 0;
}

.events-results__list{
  list-style-type:none;
  margin:0;
  padding:0;
  >li{
    margin-bottom:2rem;
    padding-bottom:2rem;
    display:flex;
    &:last-child{
      border-bottom:0;
    }
  }
}
.events-wrapper{
  max-width:350px;
  margin-right:2rem;
  flex:0 1 33%;
}
.events-card{
  flex:0 1 67%;
}
.sideBar-CTA-links{
  .events-wrapper{
    max-width:100px;
  }
  .events-results__list{
    >li{
      padding-bottom:0;
      border-bottom:0;
      flex-direction:row;
      .events-wrapper{
        margin-right:2rem;
        margin-bottom:0;
      }
    }
  }
}

.events-header{
  h2{
    margin:0;
    font-family:$base-font-family;
    font-size:1.25rem;
    line-height:1.5rem;
    &:after{
      content:none;
    }
  }
  time{
    text-transform:uppercase;
    color:$grey-600;
    font-size:.875rem;
    display:block;
    font-weight:600;
  }
}

.events-body{
  p{
    margin:0;
  }
}
.events-footer{
  margin-top:1rem;
}

.time-location-wrapper{
  margin-bottom:2rem;
  time{
    text-transform:uppercase;
    color:$grey-600;
    font-size:.875rem;
    display:inline-block;
    font-weight:600;
    margin-right:2rem;
    vertical-align: middle;
  }
  span{
    text-transform:uppercase;
    color:$grey-600;
    font-size:.875rem;
    display:inline-block;
    vertical-align: middle;
  }
}

@media (max-width:991px){
  .events-search{
    padding:0;
    .tool-toggle.open{
      margin-bottom:1rem;
    }
  }
  .events-wrapper{
    margin-right:0;
    margin-bottom:2rem;
  }
  .events-results__list{
    >li{
      flex-direction:column;
    }
  }
}
@include desktop{
  .events-search{
    .btn{
      margin-bottom:0;
    }
  }
}
