//Footer
.site-footer{
  background-color:$secondary;
  color:#fff;
  padding:4.6875rem 0 2rem 0;
  a{
    color:#fff;
    font-weight:300;
    &:hover, &:focus{
      text-decoration: underline;
    }
  }
  .footer-nav ul{
    padding:0;
    list-style-type:none;
  }
  .nav-title{
    font-weight:500;
    padding-bottom:.5rem;
    border-bottom:1px solid #fff;
    margin-top:0;
  }
  .nav-list{
    margin-top:1.25rem;
    &.show{
      margin-bottom:2rem;
    }
  }
  #hospitalLocationsFooter,#getInTouchFooter{
      display:flex;
      flex-wrap: wrap;
  }
  #audienceNavigatorFooter li, #hospitalLocationsFooter li, #aboutMcLarenFooter li{
    margin-bottom:1rem;
  }
  #hospitalLocationsFooter li{
     flex:0 1 100%;
     max-width:100%;
  }
  #getInTouchFooter{
    align-items:center;
    justify-content: space-between;
    a:hover,a:focus{
      >img{
        opacity:.8;
      }
    }
    .btn{
        margin-bottom:0;
    }
  }
  .privacy{
    list-style-type:none;
  }
  .footer-bottom{
    margin-top:1.5rem;
    text-align:center;
    .grid{
      justify-content: center;
      .privacy{
        margin:0;
        li{
          a{
            padding-right:.75rem;
            margin-right:.75rem;
          }
          a:first-of-type{
            border-right:1px solid #fff;
          }
          a:last-of-type{
            margin-right:0;
            padding-right:0;
          }
        }
      }
      .copyright{
        margin:1.5rem auto 0 auto;
        font-size:.75rem;
        max-width:280px;
      }
    }
  }
  address,.logo-footer{
    margin:0 auto;
    text-align:center;
    display:block;
    font-style:normal;
  }
  address{
    margin:2rem 0 4rem 0;
    font-weight:300;
  }
  #getInTouchTitle{
    display:none;
  }
}

@include mobile{
  .site-footer{
    #getInTouchFooter{
      justify-content: center;
      >li.nav-item{
        &:first-child{
          flex:0 1 100%;
        }
        &:not(:first-child){
          padding:0 1rem;
        }
        .btn{
          width:100%;
          margin-bottom:3rem;
        }
      }
    }
  }
}
@include tablet{
  .site-footer{
    #getInTouchFooter{
      padding-right:5rem;
    }
    .nav-title{
      margin-bottom:0;
    }
  }
}

@include desktop{
  .site-footer{
    #hospitalLocationsFooter{
      display:flex;
      flex-wrap: wrap;
    }
    #hospitalLocationsFooter li{
      flex:0 1 50%;
      max-width:50%;
    }
    .footer-bottom{
      margin-top:3rem;
      .grid{
        justify-content: center;
        .privacy{
          margin:0;
          font-size:.875rem;
          text-align:left;
          li{
            a{
              padding-right:.75rem;
              margin-right:.75rem;
            }
            a:first-of-type{
              border-right:1px solid #fff;
            }
            a:last-of-type{
              margin-right:0;
              padding-right:0;
            }
          }
        }
        .copyright{
          text-align:right;
          margin:0;
          font-size:.75rem;
          max-width:100%;
          br{
            display:none;
          }
        }
      }
    }
    .nav-title{
      &[data-toggle="collapse"]{
        &:after{
          display:none;
        }
      }
    }
    #getInTouchTitle{
      display:block;
    }
  }
}
