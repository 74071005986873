.physician-search{
  background-color:$light;
  .tabs .tabs__panels{
    margin-top:2rem;
  }
}

@include desktop{
  .physician-search{
    .checkbox{
      &.row{
        display:flex;
        label{
          margin-bottom:0;
          padding:.75rem 1rem .75rem 2rem;
          float:none;
          &:before{
            top:14px;
          }
          &:after{
            top:16px;
          }
        }
      }
    }
    .btn{
      margin-bottom:0;
      margin-top:2rem;
    }
  }
}
