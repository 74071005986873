.search-global__results{
  background-color:#fff;
  padding:2rem;
  .global-result-category{
    color:$black;
    display:flex;
    flex-wrap:wrap;
    margin-bottom:1.5rem;
    padding-top:2rem;
    margin-top:2rem;
    border-top:1px solid $grey-600;
    span{
      font-weight:600;
      flex:0 1 auto;
      font-size:1.5rem;
    }
    a{
      flex:0 1 auto;
      margin-left:auto;
      font-weight:500;
    }
  }
  ul{
    margin-top:0;
    li{
      a{
        font-size:1.25rem;
      }
    }
  }
  .events-results__list{
    color:$grey-600;
    >li{
      flex:0 1 100%;
      max-width:100%;
      padding-right:0;
      padding-bottom:0;
      margin-bottom:2rem;
      h3{
        line-height:24px;
      }
    }
  }
  .blog-news-results__list{
    >li{
      padding-bottom:0;
      margin-bottom:2rem;
      border-bottom:0;
    }
  }
  .grid>.col-6:nth-child(even){
    border-left:0;
  }
  .center-btn{
    margin:5rem auto 0rem;
    display:flex;
    justify-content:center;
    .btn{
      text-align:center;
      padding:.6rem 1rem .6rem 1rem;
      &:after{
        content:none;
      }
    }
  }
}

.modaal-wrapper{
  .search-global__results>.grid>div:nth-child(-n+2) .global-result-category{
    margin-top:0;
    padding-top:0;
    border-top:0;
  }
}

@media (max-width:991px){
  .search-global__results{
    >.grid{
      >div:nth-child(-n+2){
        .global-result-category{
          margin-top:0;
          padding-top:0;
          border-top:0;
        }
      }
    }
  }
}
