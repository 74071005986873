// --------------------------------------------------
// reflex containers
// --------------------------------------------------

.#{$reflex-prefix}container,
.#{$reflex-prefix}container-full {
    @include box-sizing(border-box);
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: ($reflex-grid-spacing * 1);
    padding-left: ($reflex-grid-spacing * 1);

    .#{$reflex-prefix}grid {
        margin-right: -$reflex-grid-spacing;
        margin-left: -$reflex-grid-spacing;
    }
}

.#{$reflex-prefix}container {
    @media (min-width: $reflex-xs) {
        max-width: $reflex-xs;
    }

    @media (min-width: $reflex-sm) {
        max-width: $reflex-sm;
    }

    @media (min-width: $reflex-md) {
        max-width: $reflex-md;
    }

    @media (min-width: $reflex-lg) {
        max-width: $reflex-lg;
    }

    @media (min-width: $reflex-xlg) {
        max-width: $reflex-lg;
    }

    @media (min-width:1550px){
      max-width:$reflex-xlg;
    }
}

// --------------------------------------------------
// reflex grid
// --------------------------------------------------

.#{$reflex-prefix}grid {
    @include box-sizing(border-box);
    display: block;
    @include display-flex();
    @include flex-wrap();
    padding: 0;
    margin: 0 auto;
    position: relative;
    @include setup-whitespace();

    &::before,
    &::after {
        @include box-sizing(border-box);
        @include reset-whitespace();
    }

    // handle when lists are used as a grid
    list-style-type: none;
}

// --------------------------------------------------
// reflex col defaults
// --------------------------------------------------

[class*="#{$reflex-prefix}col-"] {
    @include box-sizing(border-box);
    @include reset-whitespace();
    position: relative;
    width: 100%;
    vertical-align: top;
    padding-left: $reflex-grid-spacing;
    padding-right: $reflex-grid-spacing;

    &::before,
    &::after {
        @include box-sizing(border-box);
        @include reset-whitespace();
    }

    // handle grids nested in columns
    .#{$reflex-prefix}grid {
        @include flex(1, 1, auto);
        margin-left: -$reflex-grid-spacing;
        margin-right:-$reflex-grid-spacing;
    }

    @if $legacy-support == true {
        display: inline-block;
        *display: inline;
        zoom: 1;
    }
}

// --------------------------------------------------
// reflex grid generation
// --------------------------------------------------

@include make-reflex-grid(col-);

@media (min-width: $reflex-xs) {
    @include make-reflex-grid(col-xs-);
}

@media (min-width: $reflex-sm) {
    @include make-reflex-grid(col-sm-);
}

@media (min-width: $reflex-md) {
    @include make-reflex-grid(col-md-);
}

@media (min-width: $reflex-lg) {
    @include make-reflex-grid(col-lg-);
}

@media (min-width: $reflex-xlg) {
    @include make-reflex-grid(col-xlg-);
}

// --------------------------------------------------
// reflex col-auto
// --------------------------------------------------

.#{$reflex-prefix}col-auto {
    @include setup-auto-cols();
}

@media (min-width: $reflex-xs) {
    .#{$reflex-prefix}col-xs-auto {
        @include setup-auto-cols();
    }
}

@media (min-width: $reflex-sm) {
    .#{$reflex-prefix}col-sm-auto {
        @include setup-auto-cols();
    }
}

@media (min-width: $reflex-md) {
    .#{$reflex-prefix}col-md-auto {
        @include setup-auto-cols();
    }
}

@media (min-width: $reflex-lg) {
    .#{$reflex-prefix}col-lg-auto {
        @include setup-auto-cols();
    }
}

@media (min-width: $reflex-xlg) {
    .#{$reflex-prefix}col-xlg-auto {
        @include setup-auto-cols();
    }
}

// --------------------------------------------------
// reflex order helpers generation
// --------------------------------------------------

@include make-order-helpers();

@media only screen and (min-width: $reflex-xs) {
    @include loop-order-helpers($reflex-columns, '-xs');
}

@media only screen and (min-width: $reflex-sm) {
    @include loop-order-helpers($reflex-columns, '-sm');
}

@media only screen and (min-width: $reflex-md) {
    @include loop-order-helpers($reflex-columns, '-md');
}

@media only screen and (min-width: $reflex-lg) {
    @include loop-order-helpers($reflex-columns, '-lg');
}

@media only screen and (min-width: $reflex-xlg) {
    @include loop-order-helpers($reflex-columns, '-xlg');
}

// --------------------------------------------------
// reflex offset helpers generation
// --------------------------------------------------

@include make-offset-helpers();

@media only screen and (min-width: $reflex-xs) {
    @include loop-offset-helpers($reflex-columns - 1, '-xs');
}

@media only screen and (min-width: $reflex-sm) {
    @include loop-offset-helpers($reflex-columns - 1, '-sm');
}

@media only screen and (min-width: $reflex-md) {
    @include loop-offset-helpers($reflex-columns - 1, '-md');
}

@media only screen and (min-width: $reflex-lg) {
    @include loop-offset-helpers($reflex-columns - 1, '-lg');
}

@media only screen and (min-width: $reflex-xlg) {
    @include loop-offset-helpers($reflex-columns - 1, '-xlg');
}

// --------------------------------------------------
// reflex row
// --------------------------------------------------

.grid-row{
  display:flex;
  width:100%;
  justify-content: center;
  align-items:center;
  box-sizing:border-box;
  letter-spacing:normal;
  word-spacing:normal;
  white-space:normal;
  position: relative;
  vertical-align: middle;
  padding-left: 15px;
  padding-right: 15px;
  zoom: 1;
  >*{
    margin:0;
    margin-right:1rem;
    &:last-child{
      margin-right:0;
    }
  }
  .form__select-wrap{
    margin-bottom:0;
  }
}
