.content-with-cards-3 {
  .content-with-cards__card {
    background-color: $secondary;
    border-radius: 8px;
    min-height: 188px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    overflow: hidden;
    height: unset;
    h3 {
      color: #fff;
      font-weight: 700;
      margin-bottom: 0;
      font-family: $base-font-family;
    }
    p {
      color: #fff;
      margin: 0;
    }
    .triangle-bottom-right {
      position: absolute;
      width: 0;
      height: 0;
      border-bottom: 90px solid $accent;
      border-left: 90px solid transparent;
      bottom: 0;
      right: 0;
      transition: all 0.3s ease-out;
      .arrow-right {
        position: absolute;
        top: 52px;
        left: -41px;
        &::before {
          content: "";
          display: inline-block;
          width: 14px;
          height: 14px;
          border-right: 3px solid #fff;
          border-top: 3px solid #fff;
          transform: rotate(45deg);
        }
      }
    }
    &:hover {
      text-decoration: none;
      .triangle-bottom-right {
        border-bottom-color: #0087bc;
      }
    }
  }
}

@media (max-width:991px){
  .content-with-cards-3 {
    .content-with-cards__card {
      border-bottom: 1px solid #fff;
      border-radius: 0;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
    }
  }
}