.events-module{
  .grid-row{
    >p{
      font-size:1.25rem;
      color:$grey-600;
    }
  }
  .events-module__wrapper{
    position:relative;
    margin-top:3.5rem;
    margin-bottom:4rem;
    >div{
      flex:0 1 100%;
      max-width:100%;
      margin-left:0%;
      margin-bottom:2rem;
      &:not(:first-child){
        .slide-header{
          >img{
            display:none;
          }
        }
        .slide-body,.slide-footer{
          margin-left:102px;
          text-align:left;
          padding-left:2rem;
        }
      }
      .slide-header{
        .date{
          background-color:rgba(0, 101, 164, .9);
          padding:1.5rem;
          margin:0;
          color:#fff;
          position:absolute;
          time{
            font-size:1.5rem;
            display:block;
            text-align:center;
            text-transform: uppercase;
            span{
              font-size:3rem;
              display:block;
              margin:1rem 0;
            }
          }
        }
        >img{
          margin-bottom:1rem;
        }
      }
      .slide-body,.slide-footer{
        text-align:left;
      }
      .slide-body{
        h3{
          margin-bottom:.5rem;
          &.h5{
            font-family:"Roboto",sans-serif;
            font-weight:bold;
          }
        }
      }
      .slide-footer{
        margin-top:.5rem;
      }
    }
  }
}

@include desktop{
  .events-module{
    .events-module__wrapper{
      >div{
        flex:0 1 48%;
        max-width:48%;
        margin-left:52%;
        margin-bottom:3.5rem;
        &:first-child{
          flex:0 1 50%;
          max-width:50%;
          position:absolute;
          left:0;
          top:0;
          right:auto;
          margin:auto;
        }
      }
    }
  }
}

@media (max-width:991px){
  .events-module{
    .grid-row{
      justify-content: flex-start;
      align-items:flex-start;
      flex-direction:column;
      p{
        margin-bottom:1rem;
      }
      .form__select-wrap{
        width:100%;
      }
    }
    .events-module__wrapper {
        margin-bottom:2rem;
        margin-top:2rem;
      &+.btn{
        margin-left:auto;
        display:flex;
        max-width:195px;
        flex:0 1 195px;
      }
    }
  }
}
