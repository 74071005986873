.related-services{
  background-color:$light;
  padding:2rem 15px;
  margin-top:3rem;
  .wrapper{
    h3{
      margin-top:0;
      margin-bottom:1rem;
    }
  }
  .tabs-panel{
    .content{
      padding:0 15px;
    }
  }
  .accordion-tabs{
    background-color:$light;
    margin:0 -30px 2rem -30px;
  }
  .btn{
    margin-bottom:0;
    display:flex;
  }
}

@include mobile{
  .related-services{
    .split-50-list{
      >li{
        flex:0 1 100%;
        max-width:100%;
        &:nth-last-child(-n+2){
          margin-bottom:1.5rem;
        }
      }
    }
  }
}
@include desktop{
  .related-services{
    padding:2.5rem;
    .accordion-tabs{
      margin-bottom:3rem;
    }
  }
}
