.physician-content__wrapper{
  .location-details__wrapper{
    address{
      &+address{
        margin-top:2rem;
        padding-top:2rem;
        border-top:1px solid $grey-600;
      }
    }
  }
  .tabs-panel .content{
    padding:50px 0;
  }
  .map-container{

  }
  .card-body,.card-footer-link{
    border:0;
    padding-left:0;
    padding-right:0;
  }
  .accordion-tabs{
    .grid{
      margin-left:-15px;
      margin-right:-15px;
    }
  }
  .physician-bio{
    margin-top:2rem;
  }
}

.review-overview{
  .review-header{
    display:flex;
    padding:2rem 0;
    border-bottom:1px solid $grey-600;
    align-items:center;
    margin-bottom:2rem;
    >span{
      flex:0 1 auto;
      margin-right:2rem;
      font-size:1.5rem;
      &:last-child{
        margin-right:0;
      }
    }
    .review-total{
      font-size:.875rem;
      color:$grey-600;
    }
  }
  .review-preview{
    ul{
      display:flex;
      flex-wrap:wrap;
      flex-direction:column;
      list-style-type:none;
      padding:0;
      margin:0;
      li{
        flex:0 1 auto;
        margin-bottom:1rem;
        display:flex;
        span,p{
          flex:0 1 auto;
          margin-right:2rem;
          margin-top:0;
          margin-bottom:0;
        }
        p:last-child{
          margin-right:2rem;
        }
      }
    }
  }
  .review-list{
    margin-top:2rem;
    ul{
      display:flex;
      flex-wrap:wrap;
      flex-direction:column;
      list-style-type: none;
      padding:0;
      margin:0;
      li{
        display:flex;
        flex-direction:column;
        margin-bottom:2rem;
        >*{
          flex:0 1 auto;
        }
        p{
          margin:0;
        }
      }
    }
  }
}

main{
  .profile-list{
    h6{
      margin-top:0;
      font-size:1.125rem;
      color:$grey-600;
      margin-bottom:.25rem;
    }
    ul{
      list-style-type:none;
      padding:0;
      margin-top:0;
      margin-bottom:3.5rem;
      li{
        margin-bottom:.5rem;
        color:$grey-600;
      }
    }
      &:last-child{
          ul{
            margin-bottom:0;
          }
      }
  }
}

@media (max-width:991px){
  .physician-content__wrapper{
    padding-top:0;
    .tabs-panel{
      .content{
        padding:2rem 0;
        >.grid{
          >div:first-child{
            display:none;
          }
        }
        .grid{
          >div{
            margin-bottom:0;
          }
        }
        .map-container{
          display:none;
        }
      }
    }

  }
}

@media (max-width:767px){
  .physician-details__wrapper{
    .physician-header{
      margin-left:0;
      .profile-image{
        position:relative;
        margin:0;
        left:0;
        width:100%;
        margin-bottom:2rem;
      }
    }
  }
}

@media (max-width:560px){
  .physician-details__wrapper{
    .physician-header{
      .profile-image{
        margin:0 auto 2rem auto;
      }
    }
  }
}

@include desktop{
  .physician-content__wrapper{
    .tabs-allowed .tabs-panel.is-hidden{
      display:block;
    }
    .tabs-panel.is-hidden .content{
      display:block;
    }
    .tabs-tab-list li{
      flex:0 1 auto;
    }
    .section-title{
      h3.h5{
        margin:0;
        font-family:$base-font-family;
        font-weight:600;
      }
    }
    .map-container{
      height:100%;
      .map-wrapper{
        height:100%;
        position:relative;
        iframe{
          position:absolute;
          top:0;
          right:0;
          bottom:0;
          left:0;
          margin:auto;
        }
      }
    }
  }
  .plans-profile {
    .physician-content__wrapper{
      .tabs-tab-list li{
        border-bottom: 1px solid #60605B;
      }
      .section-title{
        h3.h5{
          color: #000A12;
        }
        a {
          font-size: 14px;
          font-weight: 300;
          color: #60605B;
        }
      }
    }
  }
}
.plans-profile {
  .physician-content__wrapper{
    .location-details__wrapper{
      address{
        &+address{
          border-top:1px solid #E5E6E7;
        }
      }
    }
  }
}