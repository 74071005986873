.locations-search{
  background-color:$light;
  .select2-search__field{
    width:100% !important;
    max-width:100% !important;
  }
  .select2-container--default{
    width:auto !important;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-search__field{
    width:200px !important;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__clear{
    display:none;
  }
}

.tool-toggle{
  display:none;
}

.locations-map{
  .locations-map__list{
    margin:0;
    padding:0;
    list-style-type:none;
    color:$grey-600;
    max-height:820px;
    overflow-y:scroll;

    h2.h4{
      margin-top:.25rem;
      margin-bottom:0rem;
      color:$black;
      &:after{
        content:none;
      }
    }
    li{
      margin-bottom:2rem;
    }
    .phone{
      font-size:1.75rem;
      font-weight:300;
      color:$grey-600;
      display:block;
      margin-bottom:1rem;
    }
    .location-header,.location-body,.location-footer{
      background-color:$light;
      padding-left:15px;
      padding-right:15px;
    }
    .location-header{
      padding-top:15px;
      padding-bottom:15px;
    }
    .location-body{
      padding-bottom:15px;
    }
    .location-footer{
      padding-bottom:15px;
    }
  }
  .map-container{
    .map-wrapper{
      position:relative;
      height:100%;
      max-height:820px;
    }
    iframe{
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      margin:auto;
      height:100%;
      width:100%;
    }
  }
}

@include desktop{
  .locations-search{
    .btn{
      margin-bottom:0;
      margin-top:2rem;
    }
  }
  .locations-map{
    margin-bottom:7rem;
    .locations-map__list{
    .location-footer{
      .btn{
        margin-bottom:0;
      }
    }
  }
}
}

@media(max-width:991px){
  .locations-map{
    margin-bottom:3rem;
    .map-container{
      display:none;
    }
    .locations-map__list{
      .location-body{
        padding-bottom:2rem;
      }
      .location-footer{
        .btn{
          margin-bottom:1rem;
        }
        >.grid{
          >div{
            &:last-child{
              .btn{
                margin-bottom:0;
              }
            }
          }
        }
      }
      .phone{
        font-size:1.25rem;
      }
    }
  }
  .locations-search{
    .tabs .tabs__panels{
      margin-top:2rem;
    }
  }
  .tool-toggle{
    display:block;
    background-color:transparent;
    width:100%;
    border:0;
    padding:.5rem 0;
    text-align:left;
    color:$primary;
    &[data-toggle="collapse"]{
      &:after{
        background-image:url('../images/icon-plus.svg');
      }
      &.open{
        &:after{
          background-image:url('../images/icon-minus.svg');
        }
      }
    }
  }
}
