//plan-list.nunjucks

.plan-options-list {
  padding: 18px 0 31px;

  .arrow-link {
    margin-bottom: 10px;
  }

  .plan-item {
    border-top: 1px solid #979797;
    padding: 57px 0 58px;
    
    &:first-child {
      border-top: none;
      padding-top: 39px;
    }

    &__headline {
      align-items: end;
      display: flex;
      margin-bottom: 21px;

      &-description {
        flex: 0 0 35%;

        h3 {
          margin-bottom: 12px;
        }
        p {
          color: $grey-600;
          margin: 0;
        }
      }
    }
  }
}

.plan-rates {
  flex: 0 0 65%;

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: end;
    
    li {
      border-bottom: 1px solid $grey-600;
      font-size: 20px;
      line-height: 46px;
      list-style: none;
      margin: 0 4%;
      text-align: right;
  
      &:last-child {
        margin-right: 0;
      }
  
      span {
        display: block;
  
        &:first-child {
          line-height: 26px;
        }
      }
    }
  }
}

.col-md-4 {
  .plan-rates {
    ul {
      align-items: start;
      flex-direction: column;
      li {
        font-size: 24px;
        text-align: left;
        margin-bottom: 40px;
        margin-left: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width:991px){
  .plan-options-list {
    padding: 13px 0 0;

    .arrow-link {
      margin-bottom: 5px;
    }
  
    .plan-item {
      padding: 14px 0 18px;

      &:first-of-type {
        border-top: none;
      }

      &__headline {
        display: block;
        margin-bottom: 0px;

        &-description {
          flex: 0 0 35%;
          margin-bottom: 23px;

          h3 {
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 28px;
            margin-top: 27px;
          }
        }
      }
    }
  }
  .plan-rates {
    margin-bottom: 24px;
    ul {
      flex-direction: column;
      align-items: start;

      li {
        margin: 0 0 21px;
        text-align: left;
      }
    }
  }
}

@include desktop{
  .col-md-4 {
    .plan-rates {
      margin-bottom: 40px;
      &:first-child {
        margin-top: 80px;
      }
      .cta-body {
        padding-top: 14px;
        ul li {
          margin-bottom: 24px;
        }
      }
    }
  }
}

@include xl-desktop{
  
}

@include xxl-desktop{
  
}

@media (min-width:1600px){
  
}

@media (max-width:1170px){
  
}
