//
// Layout Settings
//
.center-heading{
  .container{
    >h1,>h2,>h3,>h4,>h5,>h6{
        text-align:left;
        position:relative;
        +p{
          position:relative;
        }
      }
  }
}

.split-50-list{
  list-style-type:none;
  padding:0;
  display:flex;
  flex-wrap:wrap;
  margin-bottom:0;
  >li{
    flex:0 1 50%;
    max-width:50%;
    margin-bottom:1.5rem;
    &:nth-last-child(-n+2){
      margin-bottom:0;
    }
  }
  &.content-list{
    list-style-type:disc;
    padding-left:1.75rem;
    >li{
      padding-right:2rem;
    }
  }
}

.split-33 {
  flex: 0 0 33%;
  display: flex;

  &-list{
    list-style-type:none;
    padding:0;
    display:flex;
    flex-wrap:wrap;
    margin-bottom:0;
    >li{
      flex:0 1 33.333333%;
      max-width:33.333333%;
      margin-bottom:1.5rem;
      &:nth-last-child(-n+3){
        margin-bottom:0;
      }
    }
  }
}

.split-25-list{
  list-style-type:none;
  padding:0;
  display:flex;
  flex-wrap:wrap;
  margin-bottom:0;
  >li{
    flex:0 1 25%;
    max-width:25%;
    margin-bottom:1.5rem;
    &:nth-last-child(-n+4){
      margin-bottom:0;
    }
  }
}

@media (max-width:991px){
  .split-50-list{
    >li{
      flex:0 1 100%;
      max-width:100%;
      &:nth-last-child(-n+2){
        margin-bottom:1.5rem;
      }
      &:last-child{
        margin-bottom:0;
      }
    }
  }
}

@media (max-width:991px){
  .split-25-list{
    >li{
      flex:0 1 50%;
      max-width:50%;
      &:nth-last-child(-n+2){
        margin-bottom:0;
      }
    }
  }
}

@media (max-width:767px){
  .split-25-list{
    >li{
      flex:0 1 100%;
      max-width:100%;
      &:last-child{
        margin-bottom:0;
      }
    }
  }
}

//min-width 768px
@include tablet{
  .center-heading{
      text-align:center;
      .container{
        >h1,>h2,>h3,>h4,>h5,>h6{
          text-align:center;
          &:after{
            margin-left: auto;
            margin-right: auto;
          }
          +p{
            text-align:left;
            max-width:80%;
            margin-left:auto;
            margin-right:auto;
          }
        }
      }
  }
}
