.connect-with-us{
  background-image:url('../images/connect-with-us-bg.jpg');
  background-repeat:no-repeat;
  background-size:cover;
  position:relative;
  color:#fff;
  &:before{
    content:'';
    height:100%;
    width:100%;
    display:block;
    background-color:rgba(0,82,133,0.8);
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
  }
  .card-footer-link{
    border:0;
    padding:0;
  }
  .container{
    >.grid{
      >div{
        text-align:left;
      }
    }
  }
}

@media (max-width:991px){
    .connect-with-us{
      .container{
        >.grid{
          >div{
            &:last-child{
              .btn-ghost{
                margin-bottom:0;
              }
            }
          }
        }
      }
      .btn-ghost{
        margin-bottom:3rem;
      }
    }
}

@include mobile{
  .connect-with-us{
    background:$primary;
    .btn-ghost{
      @include button-bg($accent);
      border:0;
      margin-bottom:1rem;
    }
    .container{
      >.grid{
        >div > *:not(.card-footer-link){
          display:none;
        }
      }
    }
  }
}
