/* ------------------------------------------
NAVIGATION STYLES
(+ responsive-nav.css file is loaded in the <head>)
--------------------------------------------- */

.page-navigation{
  display:flex;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}

.nav-collapse,
.nav-collapse * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.nav-collapse {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border:1px solid $grey-400;
}

.nav-collapse,
.nav-collapse ul {
  list-style: none;
  width: 100%;
  float: left;
}

.js .nav-collapse .dropdown-toggle {
  border:0;
  color: $primary;
  content: "";
  height: 46px;
  width: 46px;
  padding: 0;
  position: absolute;
  margin: auto;
  text-transform: lowercase;
  top: 3px;
  left:auto;
  right: 2%;
  background-color:transparent;
}
.js .nav-collapse .dropdown-toggle:hover,
.js .nav-collapse .dropdown-toggle:focus,
.js .nav-collapse .dropdown-toggle:active  {
  background-color:$light;
  color: #212428;
}

.js .nav-collapse .dropdown-toggle:after {
  content: "";
  background-image:url('../images/icon-plus.svg');
  background-repeat:no-repeat;
  width:46px;
  height:46px;
  background-size:15px;
  background-position:center;
  display:block;
}
.js .nav-collapse .dropdown-toggle.toggled:after {
  content: "";
  background-image:url('../images/icon-minus.svg');
  background-repeat:no-repeat;
  width:46px;
  height:46px;
  background-size:15px 5px;
  background-position:center;
  display:block;
}

.nav-collapse li {
  float: left;
  width: 100%;
}


.nav-collapse a {
  background: #fff;
  border-bottom: 1px solid $grey-400;
  color: $primary;
  float: left;
  padding: 0.9rem 1.1rem;
  text-decoration: none;
  width: 100%;
  font-size:1.25rem;
  font-weight:500;
  transition: all 0.3s ease-out;
  &.current-page{
    color:$black;
    position:relative;
    &:before{
      content:'';
      display:block;
      position:absolute;
      height:100%;
      width:5px;
      background-color:$tertiary;
      left:0;
      top:0;
      bottom:0;
    }
  }
}
.nav-collapse{
  a.section-home{
    padding: 0.9rem 1.1rem;
    @include button-bg($accent); //Darken on hover/focus
    color:#fff;
    font-size:1.5rem;
    font-weight:500;
    display:block;
  }
}




/* ------------------------------------------
NAV TOGGLE STYLES
--------------------------------------------- */
.page-navigation-mobile-container{
  background-color:$primary;
  max-height:0;
  visibility: hidden;
  &.completed{
    max-height:100%;
    visibility: visible;
  }
}
@include desktop{
  .page-navigation-mobile-container{
    display:none;
  }
}
.nav-toggle {
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  width:100%;
  margin:0;
  border:0;
  padding:7px 0;
  text-align:left;
  display:block;
  text-transform:uppercase;
  color:#fff;
  background-color:transparent;
}

.nav-toggle:hover,
.nav-toggle:focus,
.nav-toggle:active {
  background-color: transparent;
  color: #fff;
}

/* ------------------------------------------
MULTIPLE LEVELS
--------------------------------------------- */



.nav-collapse a:hover,
.nav-collapse li.focus > a {
  background-color: $light;
}

.js .nav-collapse.closed {
  visibility: hidden;
  left:0;
}
.js .nav-collapse.opened {
  visibility: visible;
  clip:auto;
  position:relative !important;
  left:0;
}

/* Enable active class to let the navigation expand over the calculated max height. */
.js .nav-collapse.multiple-level-nav.opened.dropdown-active {
  max-height: 9999px !important;
}

.js .nav-collapse.multiple-level-nav .sub-menu {
  clip: rect(0 0 0 0);
  max-height: 0;
  position: absolute;
  display: none; /* This way you don't have focus on sub menu itrems unless you open the sub menu. */
  overflow: hidden;
  zoom: 1;
}

.js .nav-collapse.multiple-level-nav .sub-menu.toggled {
  display: block;
  max-height: 9999px;
  position: relative;
  >li{
    a{
      padding-left:2.2rem;
      border-bottom:1px solid #c5c5ba;
    }
    >.sub-menu{
      >li{
        a{
          padding-left:3.3rem;
        }
      }
    }
  }
}

.js .nav-collapse li {
  position: relative;
}



.dropdown ul a {
  background: #282e34;
  padding-left: 1.5rem;
}

.dropdown ul ul a {
  background: #2d353e
  ;
  padding-left: 2.5rem;
}

.dropdown ul ul {
  border: 0;
  padding: 0;
}

.nav-collapse ul {
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
  list-style: none;
}

.nav-collapse li {
  width: 100%;
  display: block;
}

.js .nav-collapse {
  clip: rect(0 0 0 0);
  max-height: 0;
  position: absolute;
  display: block;
  overflow: hidden;
  zoom: 1;
}

.nav-collapse.opened {
  max-height: 9999px;
}

.nav-toggle {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  &:after{
    content:'';
    display:block;
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:auto;
    margin:auto;
    background-image:url('../images/icon-plus-sm.svg');
    background-repeat:no-repeat;
    background-size:18px;
    height:18px;
    width:18px;
  }
  &.active{
    &:after{
      background-image:url("../images/icon-minus-sm.svg");
      height:5px;
    }
  }
}

@include desktop{
  .nav-toggle{
    height:0;
    width:0;
    visibility: hidden;
    margin:0;
    border:0;
    padding:0;
  }
}


/* ------------------------------------------
Mobile Secondary Nav Styles
--------------------------------------------- */
@media (max-width:991px){
  .js .nav-collapse{
    left:0;
    position:absolute !important;
    overflow:visible;
    z-index:999;
    &.opened{
      position:absolute !important;
      overflow:visible;
      z-index:999;
    }
    .sub-menu{ // First Children
      background-color:$primary;
      li{
        a{
          font-weight:400;
          font-size:1rem;
        }
      }
      .sub-menu{ // Second Children
        background-color:$secondary;
        >li{
          a{
            background-color:$secondary;
            font-size:.875rem;
          }
        }
      }
    }
    .dropdown-toggle{
      height:32px;
      width:32px;
      top:4px;
      &:after{
        background-image:url('../images/icon-plus-sm.svg');
        height:32px;
        width:32px;
      }
      &.toggled{
        &:after{
          background-image:url('../images/icon-minus-sm.svg');
          height:32px;
          width:32px;
        }
      }
      &:hover,&:focus,:active{
        background-color:$primary;
      }
    }
    &.multiple-level-nav{
      .sub-menu{
        &.toggled{
          >li{
            a{
              border-top:0;
              border-bottom:0;
              padding:.6rem 1.1rem .6rem 1.1rem;
            }
          }
          .sub-menu{
            &.toggled{
              li{
                a{
                  padding:.6rem 1.1rem .6rem 2.2rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .js .nav-collapse-0{
    &.opened{
      max-height:100% !important;
    }
  }

  .nav-collapse{
    border:0;
    a{
      font-size:1.17rem;
      color:#fff;
      background-color:$primary;
      border-top:1px solid #DDE2E8;
      border-bottom:0;
      padding:.6rem 1.1rem .6rem 0;
      width:calc(100% - 30px);
      margin:0 auto;
      display:block;
      float:none;
      &:hover,&:focus{
        background-color:$primary;
      }
      &.current-page{
        font-weight:600;
        color:#fff;
        position:initial;
        &:before{
          height:28px;
          margin:auto;
        }
      }
      &.section-home{
        display:none;
      }
    }
    li{
      &.focus{
        >a{
          background-color:$primary;
        }
      }
    }
    .menu-items{
      background-color:$primary;
      >li:first-of-type{
        a{
          border-top:0;
        }
      }
    }
  }

  .nav-toggle{
    font-size:1.17rem;
  }
}
