.breadcrumb {
  padding:0;
  margin:.5rem 0 .5rem 0;
  clear: both;
  list-style: none;
  .breadcrumb-item {
    display:inline-block;
    list-style: none;
    &:after {
      display: inline-block;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      color: $primary;
      font-weight: normal;
      content: ' > ';
    }
    &:last-child{
      &:after{
        display: none;
      }
    }
    a{
      font-size:.875rem;
      color:$primary;
      &[aria-current='page']{
         color:$primary;
      }
    }
  }
}
