
main{

.events-overview__wrapper{
  .location-name{
    margin-bottom:.5rem;
    margin-top:0;
    padding-right:5rem;
  }
  .hours{
    margin-bottom:.5rem;
    margin-top:0;
  }

  h6{
    color:$black;
    margin-bottom:0;
  }
  address{
    font-size:1.125rem;
    color:$grey-600;
    line-height:2rem;
  }
  .contact-wrapper{
    margin-top:1.75rem;
    .phone{
      font-weight:600;
      a{
        font-weight:600;
      }
    }
    span{
      >a{
        margin-left:.5rem;
        color:$grey-600;
        font-weight:400;
      }
    }
  }

  .hours-wrapper{

    .date{
      margin-top:0;
    }
    time{
      font-weight:600;
      &.start{
        font-weight:400;
        display:block;
      }
    }
  }
  .cost-wrapper{
    margin-bottom:1.75rem;
  }
}


}

@media (max-width:991px){
  .hours-wrapper{
    margin-top:2rem;
  }

}
