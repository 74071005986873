
.search-list{
  list-style:none;
  margin:0;
  padding:0;
  >li{
    margin-bottom:2rem;
    padding-bottom:2rem;
    border-bottom:1px solid $grey-600;
    &:last-child{
      margin-bottom:0;
    }
  }
}

.search-header{
  h2{
    margin:0;
    font-size:1.25rem;
    font-family:$base-font-family;
    line-height:2.5rem;
    &:after{
      content:none;
    }
  }
}

.search-body{
  p{
    margin:0;
  }
}

.search-footer{
  .container{
    padding:0;
  }
}
