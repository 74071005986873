//Sliders

//Patient Story Sliders
.patient-stories__slider{
  margin-bottom:2rem;
  .slick-slide{
    text-align:left;
    color:$grey-600;
    width:350px;
  }
  .slide-header{
    padding:1.5rem;
    background-color:#EEF7FC;
    border-top-right-radius:4px;
    border-top-left-radius:4px;
    p{
      font-size:.875rem;
      margin:0;
    }
    p.name{
      font-size:1.125rem;
      font-weight:500;
      margin-bottom:.25rem;
    }
  }
  .slide-body{
    background-color:#EEF7FC;
    padding:0 1.5rem;
    p{
      font-size:1.125rem;
      margin:0;
    }
  }
  .slide-footer{
    background-color:#EEF7FC;
    padding:1.5rem;
    margin-bottom:5.5rem;
    display:flex;
    justify-content: flex-end;
    position:relative;
    border-bottom-right-radius:4px;
    border-bottom-left-radius:4px;
    &:after{
      content:'';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 36px 28px 0 0;
      border-color: #EEF7FC transparent transparent transparent;
      position:absolute;
      bottom:-36px;
      left:130px;
    }
  }
  .circle-img-frame{
    position:absolute;
    height:120px;
    width:120px;
    border-radius:50%;
    overflow:hidden;
    border:8px solid #fff;
    left:-2px;
    img{
      width:100%;
      height:auto;
    }
  }
}

.slick-arrow{
  height:48px;
  width:48px;
  background-color:#EEF7FC;
}

.slick-prev,.slick-next{
  bottom:-80px;
  left:0;
  top:auto;
  transform:translate(0);
}

.slick-prev{
  &:hover,&:focus{
    background:darken(#EEF7FC,6%);
  }
}

.slick-next{
  left:48px;
  background:$tertiary;
  &:hover,&:focus{
    background:darken($tertiary,6%);
  }
}

.slick-prev:before, .slick-next:before{
  background-repeat:no-repeat;
  background-position:center;
  display:block;
  height:16px;
  width:13px;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
}

.slick-prev:before{
  content:'';
  background-image:url('../images/chevron-left.svg');
}

.slick-next:before{
  content:'';
  background-image:url('../images/chevron-right.svg');
}

@include desktop{
  .patient-stories__slider{
    margin-bottom:3rem;
    .slick-slide{
      margin-left:15px;
      margin-right:15px;
    }
  }
  .text-cards__slider{
    margin-bottom:3rem;
    .slick-slide{
      margin-left:15px;
      margin-right:15px;
    }
  }
}

@media (max-width:991px){
  .patient-stories__slider{
    +.btn{
      margin-left:auto;
      display:flex;
      max-width:265px;
      flex:0 1 265px;
    }
  }
}
