.events-results__list{
  position:relative;
  margin-top:0rem;
  margin-bottom:4rem;
  display:flex;
  flex-wrap:wrap;
  >li{
    flex:0 1 100%;
    max-width:100%;
    margin-left:0%;
    margin-bottom:2rem;
    flex-direction:column;

      .slide-body,.slide-footer{
        margin-left:102px;
        text-align:left;
        padding-left:2rem;
      }

    .slide-header{
      .date{
        background-color:rgba(0, 101, 164, .9);
        padding:1.5rem;
        margin:0;
        color:#fff;
        position:absolute;
        time{
          font-size:1.5rem;
          display:block;
          text-align:center;
          text-transform: uppercase;
          span{
            font-size:3rem;
            display:block;
            margin:1rem 0;
          }
        }
      }
      >img{
        margin-bottom:1rem;
      }
    }
    .slide-body,.slide-footer{
      text-align:left;
    }
    .slide-body{
      h3{
        margin-bottom:.5rem;
        margin-top:0;
        &.h5{
          font-family:"Roboto",sans-serif;
          font-weight:bold;
        }
      }
    }
    .slide-footer{
      margin-top:.5rem;
    }
  }
}

@include desktop{
    .events-results__list{
      >li{
        flex:0 1 50%;
        max-width:50%;
        margin-bottom:3.5rem;
          padding-right:2rem;
      }
    }
}

@media (max-width:991px){
    .events-results__list{
        margin-bottom:2rem;
        margin-top:2rem;
      &+.btn{
        margin-left:auto;
        display:flex;
        max-width:195px;
        flex:0 1 195px;
      }
    }
  }
