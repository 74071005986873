.providers-search {
    background-color: $light;
    h2 {
        color: $secondary;
        font-size: 32px;
        margin-top: 29px;
        margin-bottom: 24px;
        max-height: 0px;
        overflow: hidden;
        transition: max-height .5s ease-in-out;
        &:after {
            width: 47px;
            margin-top: 18px;
       }
       &.show {
           max-height: 99em;
       }
   }
   label {
        font-size: 20px;
        margin-bottom: 4px;
   }
    .radio {
        padding: 12px 0 9px;
        input[type='radio'] {
            display: none;
        }
        label {
            margin-bottom: 16px;
        }
   }
   
   .select2-container--default {
        .select2-selection--multiple,
        .select2-selection--single {
            height: 44px;
            .select2-selection__rendered {
                display: flex;
                align-items: center;
                height: 100%;
            }
        }
   }
    .form__input {
        height: 44px;
        padding: 0 1rem 0 1rem;
   }
    .tabs {
        &__nav-trigger {
            font-size: 20px;
       }
        &__panels {
            margin-top: 2rem;
            
            .tabs__panel {
                p {
                    color: $grey-600;
                }
            }
       }
       &__nav-item {
            &.is-active {
                .tabs__nav-trigger{
                    &:after {
                        bottom: 0;
                    }
                }
            }
            .tabs__nav-trigger {
                padding: 17px 20px 7px;
                margin-left: 0;
           }
           
       }
       &__navlist {
            border-bottom: 1px solid #979797;
       }
   }
    .form-grid {
        display: flex;
        flex-direction: column;
        padding-top: 5px;
    }
    .form__select-wrap {
        margin-bottom: 27px;
    }
    .advanced-options {
        max-height: 0;
        overflow: hidden;
        transition: max-height .5s ease-in-out;
        &.show {
            display: flex;
            flex-direction: column;
            max-height: 99em;
        }
    }
    #advanced-search-provider {
        display: none;
        font-size: 18px;
        &.show {
            display: inline;
        }
    }
    &__cta {
        display: flex;
        align-items: center;
        margin-bottom: 110px;
    }
}
.provider-search-fields {
    .headline {
        background-color: #0065A4;
        border-radius: 4px 4px 0 0;
        padding: 10px 22px 9px;
        h5 {
            color: $white;
            margin: 0;
        }
    }
    .form-grid {
        background: #F8F8F8;
        border: 1px solid #B8C1CB;
        border-radius: 0 0 4px 4px;
        display: flex;
        flex-direction: column;
        padding: 16px 14px;

        label {
            color: $grey-600;
            font-size: 20px;
            font-weight: 400;;
            margin-bottom: 4px;
        }
        .select2-container--default {
            .select2-selection--single {
                height: 44px;
                .select2-selection__rendered {
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            }
        }
        .form__select-wrap,
        .form__input-wrap {
            margin-bottom: 19px;
        }
        .form__input {
            height: 44px;
            padding: 0 1rem 0 1rem;
        }
        .radio {
            padding: 9px 0 1px;
            input[type='radio'] {
                display: none;
            }
            label {
                margin-bottom: 16px;
            }
        }
        .providers-search__cta {
            margin-bottom: 16px;
            margin-top: 5px;
            .btn {
                margin-bottom: 0;
            }
        }
    }
}
@include desktop {
    .providers-search {
        .checkbox {
            &.row {
                display: flex;
                label {
                    margin-bottom: 0;
                    padding: 0.75rem 1rem 0.75rem 2rem;
                    float: none;
                    &:before {
                        top: 14px;
                   }
                    &:after {
                        top: 16px;
                   }
               }
           }
       }
        .btn {
            margin-bottom: 0;
            margin-top: 0;
            margin-right: 1rem;
            width: 160px;
       }
       .tabs {
            .tabs__panel {
                max-width: 735px;
                margin: 0 auto;
            }
       }
   }
}
@include mobile {
    .providers-search {
        .tabs {
            .form-grid [class*="col-"] {
                width: 100%;
                padding: 0;
            }
        }
        &__cta {
            .btn {
                margin-bottom: 0;
                margin-right: 1rem;
            }
        }
    }
    .provider-search-fields {
        margin-bottom: 32px;
    }
}