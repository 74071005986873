.cta-button-block{
  background-color:$primary;
  color:#fff;
  padding:3rem 15px;
  margin-bottom:0;
  .grid{
    justify-content: center;
  }
  h2{
    margin-top:0;
  }
}

@include desktop{
  .cta-button-block{
    .btn{
      margin-bottom:0;
    }
  }
}
