
/*Default Tab Styles - START*/
.accordion-tabs {
  color: #0c1333;
  background-color:#fff;
  margin:0 -15px;
}

.accordion-tabs{
.grid{
  margin:0;
}
}

.tabs-tab-list {
display: flex;
list-style: none;
margin: 0;
padding: 0;
}

.tabs-tab-list li {
margin: 0;
flex:1;
}

.tabs-trigger {
border-bottom: none;
color: $grey-600;
display: none;
font-weight: 600;
margin: 0 5px 0 1px;
padding: 15px 20px;
text-decoration: none;
position:relative;
text-align:center;
}

.tabs-trigger:hover {
border-bottom: none;
color: #000;
}

.tabs-trigger.is-selected, .tabs-trigger.is-selected:hover, .tabs-trigger.is-selected:focus {
color: $primary;
}
/*Default */
.tabs-trigger[aria-selected="false"]:after{
content: '';
display: block;
border-bottom: 0px solid $primary;
width: 100%;
left:0;
bottom:0px;
position:absolute;
-webkit-transition: .25s ease;
        transition: .25s ease;
}
/*Active Selected*/
.tabs-trigger.is-selected:after{
content:'';
display: block;
border-bottom: 3px solid $primary;
width:100%;
right:0;
bottom:0px;
position:absolute;
-webkit-transition: .25s ease;
        transition: .25s ease;
}

.tabs-trigger:active {
outline:none;
}



.tabs-panel{
.content{
  margin-top: 10px;
  padding: 30px 0 15px 0;
  .grid{
    >div{
      margin-bottom:3rem;
    }
  }
}
}

.tabs-panel.is-hidden .content {
display: none;
}

.tabs-panel:active, .tabs-panel:focus {
outline: none;
}

.is-initialized.tabs-allowed .tabs-panel {
display: inherit;
}

.accordeon-trigger {
display: flex;
align-items: center;
justify-content: space-between;
cursor: pointer;
padding: 15px 20px 15px 15px;
font-size:1.25rem;
color:$primary;
position:relative;
border-bottom:1px solid #979797;

&[aria-expanded="false"]:after{
  content:'';
  background-image:url("../images/icon-plus.svg");
  background-repeat:no-repeat;
  background-size:28px;
  height:28px;
  width:28px;
  display:block;
  position:absolute;
  top:0;
  right:15px;
  bottom:0;
  left:auto;
  margin:auto;
}

&[aria-expanded="true"]:after{
  content:'';
  background-image:url("../images/icon-minus.svg");
  background-repeat:no-repeat;
  background-size:28px;
  height:28px;
  width:28px;
  display:block;
  position:absolute;
  top:0;
  right:15px;
  bottom:0;
  left:auto;
  margin:auto;
}
}

.accordeon-trigger-icon {
pointer-events: none;
}

#awardsTab{
.card-header-img{
  border:0;
  overflow:visible;
  border-radius:0;
}
.card-body{
  border:0;
  padding-left:0;
  padding-right:0;
  h3{
    font-size:1.125rem;
    line-height:1.5rem;
  }
}
}

@media (min-width: 40em) {
  .tabs-allowed .accordeon-trigger {
    display: none;
  }
  .tabs-allowed .tabs-trigger {
    display: block;
  }
  .tabs-allowed .tabs-panel {
    display: none;
  }
  .tabs-allowed .tabs-panel.is-hidden {
    display: none;
  }
  .tabs-allowed .tabs-panel {
    border-top: 2px solid #eee;
  }
  .tabs-allowed .tabs-tab-list {
    margin-bottom: -2px;
  }
  .plans-profile {
    .tabs-allowed .tabs-panel {
      border-top: 1px solid #E5E6E7;
    }
    .tabs-allowed .tabs-tab-list {
      margin-bottom: 0;
    }
  }
}

.accordeon-trigger-icon {
display: inline-block;
float: right;
width: 22px;
height: 22px;
}

.accordeon-trigger-icon svg {
margin: 0;
}

[aria-expanded="true"] .vert {
display: none;
}

.label--open, .label--close {
display: block;
border: 0;
clip: rect(0 0 0 0);
height: 1px;
margin: -1px;
overflow: hidden;
padding: 0;
position: absolute;
width: 1px;
}

.label--close {
display: none;
}

.is-open .label--open {
display: none;
}

.is-open .label--close {
display: block;
}

/*Default Tab Styles - END*/


@include desktop{
  .accordion-tabs{
    .center-btn{
      .btn{
        flex:0 0 calc(33.33333% - 20px);
        max-width:calc(33.33333% - 20px);
        margin-top:3.5rem;
      }
    }
  }
  .tabs-panel{
    .content{
      margin-top: 10px;
      padding: 50px 0 0;
      .grid{
        >div{
          margin-bottom:0rem;
        }
      }
    }
  }
  .plans-profile {
    .tabs-panel .content .location-details__wrapper .grid{
      margin-left: 0;
      >div {
        padding-left: 0;
    } 
    }
  }
}

@include mobile{
.media-tabs{
  padding-top:0;
  .center-btn{
    max-width:calc(100% - 30px);
    margin:0 auto;
  }
}

.tabs-panel {
  display: block;
  &.is-open{
    border-bottom:1px solid #979797;
  }
}
}


//Basic Tabs
.tabs .tabs__navlist {
display: flex;
list-style: none;
margin: 0;
padding: 0;
border-bottom:3px solid #EEEEEE;
}

.tabs .tabs__nav-item {
margin:0;
flex:1;
}
.tabs .tabs__nav-item.is-active .tabs__nav-trigger {
  color: $primary;
  &:after{
    content:'';
    display: block;
    border-bottom: 3px solid $primary;
    width:100%;
    right:0;
    bottom:-3px;
    position:absolute;
    -webkit-transition: .25s ease;
    transition: .25s ease;
  }
}
.tabs .tabs__nav-item .tabs__nav-trigger {
  border: none;
  color: $grey-600;
  font-weight: 600;
  margin: 0 5px 0 1px;
  padding: 15px 20px;
  text-decoration: none;
  position:relative;
  text-align:center;
  display:block;
  width:100%;
  background-color:transparent;
  &:after{
    content: '';
    display: block;
    border-bottom: 0px solid $primary;
    width: 100%;
    left:0;
    bottom:0px;
    position:absolute;
    -webkit-transition: .25s ease;
    transition: .25s ease;
  }
  &:hover{
    border-bottom: none;
    color: #000;
  }
  &:focus{
    outline:0;
  }
}

.tabs .tabs__panels {
padding: 0 15px;
margin-top:10px;
}

.tabs .tabs__panel {
display: none;
}
.tabs .tabs__panel.is-current {
  display: block;
  &:focus{
    outline:0;
  }
}

.plans-profile {
  .tabs-tab-list li {
    margin: 0 0 -1px;
  }
  .tabs-trigger {
    font-weight: 700;
    font-size: 20px;
  } 
}