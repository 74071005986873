//
// Global Settings
//


//Wireframe Styles END

body{
  margin: 0 auto;
  position:relative;
  max-width:1920px;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.visuallyhidden{
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}

a{
  color:$primary;
  font-weight:500;
  text-decoration: none;
  &:not(.btn){
    &:hover{
      text-decoration: underline;
    }
  }
}

:focus{
  outline:$accent auto 2px;
}

.pre-header{
  font-size:.875rem;
  text-transform: uppercase;
  color:$grey-600;
  font-weight:500;
  padding: 11px 0 0;
}

// Font Styles START
h1,h2,h3,h4,h5,h6{
  font-weight:300;
}
h1,h2,h3,.h1,.h2,.h3{
  font-family:$header-font-family;
}
h4,h5,h6{
  font-weight:500;
}

.h1{
  font-size:$h1-font-size;
}

.h2{
  font-size:$h2-font-size;
}

.h3{
  font-size:$h3-font-size;
}

.h4{
  font-size:$h4-font-size;
}

.h5{
  font-size:$h5-font-size;
}

.h6{
  font-size:$h6-font-size;
}


h1{
  text-transform: uppercase;
}

//Add border after h2
h2{
  &:after{
    content:'';
    height:4px;
    background:$tertiary;
    width:100px;
    display:block;
    margin-top:1.5rem;
  }
}

h2.h1{
  &:after{
    height:0;
    width:0;
    margin-top:0;
  }
}


h5,h6,.h5,.h6{
  &+p{
    margin-top:0;
  }
}

h2,.h2{
  line-height:3rem;
}


h3,.h3{
  line-height:2.5rem;
}

h4,h5,.h4,.h5{
  line-height:2rem;
}

h6,.h6{
  line-height:1.5rem;
}


@include mobile{
  h1,.h1{
    font-size:1.75rem;
    line-height:2.25rem;
  }
  h2,.h2{
    font-size:1.75rem;
    line-height:2.25rem;
  }
  h3,.h3{
    font-size:1.625rem;
    line-height:2rem;
  }
  h4,.h4{
    font-size:1.5rem;
    line-height:2rem;
  }
  h5,.h5{
    font-size:1.25rem;
    line-height:1.5rem;
  }
  h6,.h6{
    font-size:1.125rem;
  }
}

// Font Styles END


[data-toggle="collapse"]{
  position:relative;
  &:after{
    content:'';
    background-image:url("../images/icon-plus-sm.svg");
    background-repeat:no-repeat;
    background-size:18px;
    height:18px;
    width:18px;
    position:absolute;
    top:0;
    right:5px;
    bottom:0;
    left:auto;
    margin:auto;
  }
  &.open{
    &:after{
      background-image:url("../images/icon-minus-sm.svg");
      height:5px;
    }
  }
}

//Global Collapse
.collapse {
  display: block;
  max-height: 0px;
  overflow: hidden;
  transition: max-height .5s cubic-bezier(0, 1, 0, 1);
  &.show {
    max-height: 99em;
    transition: max-height .5s ease-in-out;
  }
}
//Overlay when clicking on mobile menu
.main-wrapper{
  &:before{
    content:'';
    position:absolute;
    display:block;
    z-index:-1;
    top:0px;
    bottom:0;
    left:0;
    right:0;
    margin:auto;
    width:100%;
    height:100%;
    background-color:$darkSecondary;
    opacity:0;
    transition: opacity .5s ease-in-out;
  }
}
//Overlay when clicking on mobile menu
.site-overlay{
  &:before{
    content:'';
    top:205px;
    opacity:1;
    z-index:8000;
  }
}

//Add download icon before icon-download
.icon-download{
  vertical-align: middle;
  position:relative;
  padding-left:1.5rem;
  &:before{
    content:'';
    background-image:url('../images/icon-download.svg');
    background-repeat:no-repeat;
    background-position:center;
    background-size:20px 16px;
    display:inline-block;
    vertical-align: middle;
    margin-right:.5rem;
    height:20px;
    width:16px;
    position:absolute;
    top:3px;
    left:0;
  }
}

//tag

.tag{
  font-size:1.17rem;
  color:$accent;
  font-weight:600;
  display:inline-block;
  margin-top:.5rem;
  margin-bottom:1rem;

  &.tag-check{
    &:before{
      content:'';
      background-image:url('../images/icon-check.svg');
      background-repeat:no-repeat;
      background-position:center;
      background-size:20px;
      display:inline-block;
      vertical-align: middle;
      margin-right:.5rem;
      height:20px;
      width:20px;
      margin-top:-4px;
    }
  }
}

.provider-results {
  .physician-header {
    padding-bottom: 11px;
    margin-bottom: 27px;
    .tag{
      margin-top: 6px;
      margin-bottom: 0px;
    }
  }
}
.physician-body {
  .pre-header {
    font-weight: 700;
  }
}

.loadmoreWrapper{
  display:flex;
  justify-content: center;
  margin-bottom:2rem;
  display:none;
  #loadMore{
    padding:.6rem 1rem .6rem 1rem;
    text-align:center;
    &:after{
      content:none;
    }
  }
}

#loadMoreList{
  >li{
    &.show{
      display:flex;
    }
    &.hide{
      display:none;
    }
  }
}

@media (max-width:991px){
  .tag{
    font-size:.875rem;
    margin-top:0;
    margin-bottom:.5rem;
  }
}

@include desktop{
  // Used to add hidden height to form elements to match other label heights
  .hiddenHeight{
    height:24px;
    margin-bottom:.5rem;
    position:relative;
    left:0;
  }
  .results{
    padding: 0;
    margin: 19px 0;
    color: $grey-600;
  }
}
