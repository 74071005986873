
// Forcing 2 Column on tabet+ with absolute position for mobile Layout
.grid-links{
  display:flex;
  flex-wrap:wrap;
  margin:40px 0;
  a{
    display:block;
    background-color:rgba(255, 255, 255, .2);
    flex:0 0 100%;
    max-width:100%;
    display:flex;
    justify-content: left;
    align-items: center;
    margin: 0 0 .5rem 0;
    min-height:3rem;
    padding:15px 20px;
    font-size:1.125rem;
    text-decoration: none;
    color:#fff;
    transition:background-color .4s;
    span{
      display:block;
      font-size:2rem;
      font-weight:600;
      height:23px;
      margin-right:1rem;
    }
    &:hover,&:focus{
      background-color:rgba(255, 255, 255, 1);
      color:$primary;
      box-shadow:0 0 10px rgba(0,0,0,0.2);
      text-decoration: none;
    }
  }
}

.locations-grid-cta{
  background-image:url('../images/locations-bg-placeholder.jpg');
  background-repeat: no-repeat;
  background-size:cover;
  background-position:center;
  overflow:hidden;
  position:relative;
  color:#fff;
  &:before{
    content:'';
    position:absolute;
    height:100%;
    width:100%;
    display:block;
    background-color:$secondary;
    opacity:.8;
    top:0;
    bottom:0;
    left:0;
    right:0;
  }
}

@include tablet{
  .grid-links{
    margin:65px 0;
    a{
      flex:0 0 29.333333333%;
      max-width:29.333333333%;
      margin:0 2%;
      height:12rem;
      justify-content: center;
      flex-direction:column;
      font-size:1.5rem;
      span{
        font-size:4rem;
        height:50px;
        margin-right:0;
      }
    }
  }
}
