// Forcing 2 Column on tabet+ with absolute position for mobile Layout
.play-button-wrapper{
  border:5px solid rgba(255,255,255,1);
  height:9rem;
  width:9rem;
  border-radius:50%;
  display:block;
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  margin:auto;
  transition:all .4s ease-out;
}

.play-button {
  position:absolute;
  top:-11px;
  right:0;
  bottom:0;
  left:-6px;
  margin:auto;
	background-color: rgba(255,255,255,1);
	text-align: left;
  transition:all .4s ease-out;
}

//2 column offset with an video
.video-wrapper{
  position:relative;
  &:hover,&:focus{
    .play-button-wrapper{
      border:5px solid $accent;
      background-color: $accent;
      box-shadow:0 0 10px rgba(0,0,0,.2);
      transform:scale(.95);
    }
    .play-button{
      background-color:rgba(255, 255, 255, 1);
    }
  }
}

.play-button:before,
.play-button:after {
	content: '';
	position: absolute;
	background-color: inherit;
}
.play-button,
.play-button:before,
.play-button:after {
	width:  2em;
	height: 2em;
	border-top-right-radius: 20%;
}

.play-button {
	transform: rotate(-90deg) skewX(-30deg) scale(1,.866);
}
.play-button:before {
	transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
}
.play-button:after {
	transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
}


@include desktop{
  .column-2-absolute-offset{
    .col-12{
      padding-bottom:3.5%;
    }
    .video-wrapper{
      max-width:44%;
      position:absolute;
      top:0;
      right:15px;
    }
    //2 column offset with an image
    .image-wrapper{
      max-width:44%;
      position:absolute;
      top:0;
      right:auto;
      left:15px;
    }
    p{
      padding-right:49%;
    }
    &.image-offset{
      h2,p,.arrow-link{
        padding-left:49%;
        padding-right:0;
      }
    }
  }
}
