//Link style
.arrow-link{
  display:inline-block;
  color:$primary;
  font-weight:500;
  text-decoration: none;
  white-space: nowrap;
  &:after{
    content:'';
    height:16px;
    width:13px;
    background-image:url('../images/chevron-right.svg');
    background-repeat:no-repeat;
    display:inline-block;
    margin-left:2px;
    background-size:16px 13px;
    background-position:0 3px;
  }
  &:hover,&:focus{
    text-decoration: underline;
    color:$accent;
    &:after{
      background-image:url('../images/chevron-right-accent.svg');
    }
  }
  &.w{
    color:#fff;
      &:after{
        background-image:url('../images/chevron-right-w.svg');
      }
  }
  &.rev{
    font-size: 18px;
    &:after{
      content:none;
    }
    &:before{
      content:'';
      height:16px;
      width:12px;
      background-image:url('../images/chevron-left.svg');
      background-repeat:no-repeat;
      display:inline-block;
      margin-right:6px;
      background-size:9px 13px;
      background-position:0 3px;
    }
    &:hover,&:focus{
      &:before{
        background-image:url('../images/chevron-left-accent.svg');
      }
    }
  }
}

//Simple links
.simple-link{
  color:#fff;
  padding-bottom:.1rem;
  margin-bottom:.25rem;
  display:inline-block;
  font-style:italic;
  font-size:.875rem;
  text-decoration: none;
  &:focus,&:hover{
    opacity:.8;
  }
}

//Current Location links
.current-location{
  position:relative;
  padding-left:25px;
  &:before{
    content:'';
    display:block;
    background-image:url('../images/icon-location-sm.svg');
    background-repeat:no-repeat;
    background-size:13px 18px;
    width:13px;
    height:18px;
    position:absolute;
    left:0;
    right:auto;
    top:0;
    bottom:0;
    margin:auto;
  }
  &.dark{
    color:$primary;
    &:before{
      content:'';
      background-image:url('../images/icon-location.svg');
      background-repeat:no-repeat;
      background-size:13px 18px;
      width:13px;
      height:18px;
      position:absolute;
      left:0;
      right:auto;
      top:0;
      bottom:0;
      margin:auto;
    }
  }
}

//Button styles
.btn{
  padding:.6rem 2.7rem .6rem 1rem;
  @include button-bg($primary); //Sets background color and hover/active state - see mixins.scss
  border-radius:4px;
  overflow:hidden;
  color:$white;
  position:relative;
  text-align:left;
  font-size:1.1875rem;
  font-weight:500;
  background-position: right 20px center;
  margin-bottom:1rem;
  cursor: pointer;
  border:0;
  display:inline-block;
  border:1px solid transparent;
  &:after{
    content:'';
    height:16px;
    width:13px;
    background-image:url('../images/chevron-right-w.svg');
    background-repeat:no-repeat;
    position:absolute;
    right:20px;
    top:0;
    bottom:0;
    left:auto;
    margin:auto;
    transition: all 0.3s ease-in-out;
  }
}

.btn-sm{
  font-size:.875rem;
  padding:.5rem 3.5rem .5rem 1rem;
}

.btn-lg{
  font-size:1.25rem;
}

.btn-light{
  @include button-bg($accent); //Sets background color and hover/active state - see mixins.scss
  background-position: right 20px center;
}

.btn-secondary{
  @include button-bg(#fff); //Sets background color and hover/active state - see mixins.scss
  border:1px solid $primary;
  color:$primary;
  background-position: right 20px center;
  &:after{
    content:'';
    background-image:url('../images/chevron-right.svg');
  }
}

input[type=button].btn{
  background-image:url('../images/chevron-right-w.svg');
  background-repeat:no-repeat;
}

input[type=button].btn-secondary{
  background-image:url('../images/chevron-right.svg');
}

.btn-ghost{
  border:1px solid #fff;
  @include button-bg($accent);
  background:transparent;
  &:hover{
    border:1px solid #005285;
  }
}


//Button Formating
.btn-full{
  width:100%;
  max-width:100%;
}

//Block Button
.btn-block{
  padding:2.18rem 1rem;
  display:inline-block;
  max-width:170px;
  text-align:center;
  margin-right:1rem;
  &:after{
    content:initial;
  }
}

//Disabled Buttons
button,input[type="button"],input[type="submit"],input[type="reset"]{
  &:disabled{
    opacity:.6;
    background:$grey-200;
    color:$grey-600;
    border:$grey-600;
    cursor:not-allowed;
    &:after{
    background-image:url('../images/chevron-right-disabled.svg');
    }
    &:hover{
      background:$grey-200;
      background-position:right 20px center;
      &:after{
        right:20px;
      }
    }
  }
}

.back-to-top{
  display:none;
}

.icon-refresh{
  &:after{
  content:'';
  background-image:url("../images/icon-refresh.svg");
  background-size:17px 16px;
  height:17px;
  width:16px;
  }
  &:hover,&:focus{
    &:after{
      right:20px;
    }
  }
}

@include desktop{
  .center-btn{
    .btn{
      flex:0 0 29.333333333%;
      max-width:29.333333333%;
      justify-content: center;
      margin-bottom:0;
    }
  }
  .back-to-top{
    height:62px;
    width:62px;
    display:block;
    border-radius:50%;
    position:relative;
    border:0;
    margin-left:auto;
    margin-top:33px;
    cursor: pointer;
    @include button-bg($accent);
    >img{
      display:block;
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      margin:auto;
    }
  }
}
